import { ElMessage } from 'element-plus'

export const testtype = {
    state: () => ({
        testtypes:[],
        testtype:{},
        countTesttype:0
    }),
    getters:{
        countTesttype(state){
            return state.countTesttype
        },
        testtypes(state){
            return state.testtypes.filter(testtype => testtype.status !==2)
        },
        testtype(state){
            return state.testtype
        },
        activeTesttypes(state){
            return state.testtypes.filter(testtype => testtype.status == 0)
        }
    },
    mutations:{
        countTesttype(state,payload){
            state.countTesttype = payload
        },
        testtypes(state,payload){
            state.testtypes = payload
        },
        testtype(state,payload){
            state.testtype = payload
        },
        newTesttype(state,payload){
            state.testtypes = [payload,...state.testtypes]
            state.countTesttype += 1
        },
        saveTesttype(state,payload){
            let index = state.testtypes.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.testtypes[index] = payload
            }
        },
        deleteTesttype(state,payload){
            state.testtypes = state.testtypes.filter(testtype => {
                if (testtype._id == payload) return false
                return testtype
            })
            state.countTesttype -= 1
        }
    },
    actions:{
        
        async getTesttypes(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'testtype',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('testtypes',res.data)
            }
        },
        async getCountTesttypes(context,payload){
            let res = await context.dispatch('getAxios',{url:'testtype/count',search:payload})
            if (res.status == 200){
                context.commit('countTesttype',res.data.testtype)
            }
        },
        async changeStatusTesttype(context,payload){
            let res = await context.dispatch('getAxios',{url:`testtype/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveTesttype',res.data)
                ElMessage({
                    message: payload.status == 2 ? 'Test turi o`chirildi' :'Test turi holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editTesttype(context,payload){
            return await context.dispatch('getAxios',{url:`testtype/edit/${payload}`})
        },
        async saveTesttype(context,payload){
            let res = await context.dispatch('putAxios',{url:`testtype/save`,data: payload})
            if (res.status == 200){
                context.commit('saveTesttype',res.data)
                ElMessage({
                    message: 'Test turi yangilandi',
                    type: 'success',
                })
            }
        },
        async addTesttype(context,payload){
            let res = await context.dispatch('postAxios',{url:'testtype',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi test turi qo`shildi',
                    type: 'success',
                })
                context.commit('newTesttype',res.data)
            }
        },
        
    }
}