import { ElMessage } from 'element-plus'

export const region = {
    state: () => ({
        regions:[],
        region:{},
        countRegion:0
    }),
    getters:{
        countRegion(state){
            return state.countRegion
        },
        regions(state){
            return state.regions
        },
        region(state){
            return state.region
        },
        activeRegions(state){
            return state.regions.filter(region => region.status == 0)
        }
    },
    mutations:{
        countRegion(state,payload){
            state.countRegion = payload
        },
        regions(state,payload){
            state.regions = payload
        },
        region(state,payload){
            state.region = payload
        },
        newRegion(state,payload){
            state.regions = [...payload,...state.regions]
            state.countRegion += payload.length
        },
        saveRegion(state,payload){
            let index = state.regions.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.regions[index] = payload
            }
        },
        deleteRegion(state,payload){
            state.regions = state.regions.filter(region => {
                if (region._id == payload) return false
                return region
            })
            state.countRegion -= 1
        }
    },
    actions:{
        async getRegions(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'region',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('regions',res.data)
            }
        },
        async getCountRegions(context,payload){
            let res = await context.dispatch('getAxios',{url:'region/count',search:payload})
            if (res.status == 200){
                context.commit('countRegion',res.data.region)
            }
        },
        async changeStatusRegion(context,payload){
            let res = await context.dispatch('getAxios',{url:`region/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deleteRegion',res.data._id)
                    ElMessage({
                    message: 'Viloyat o`chirildi',
                        type: 'warning',
                    })
                } else {
                    context.commit('saveRegion',res.data)
                    ElMessage({
                    message: 'Viloyat holati o`zgartirildi',
                        type: 'success',
                    })
                }
            }
        },
        async editRegion(context,payload){
            return await context.dispatch('getAxios',{url:`region/${payload}`})
        },
        async saveRegion(context,payload){
            let res = await context.dispatch('putAxios',{url:`region`,data: payload})
            
            if (res.status == 200){
                context.commit('saveRegion',res.data)
                ElMessage({
                    message: 'Viloyat yangilandi',
                    type: 'success',
                })
            }
        },
        async addRegion(context,payload){
            let res = await context.dispatch('postAxios',{url:'region',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi viloyatlar qo`shildi',
                    type: 'success',
                })
                context.commit('newRegion',res.data)
            }
        },
    }
}