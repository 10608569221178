import { ElMessage } from 'element-plus'

export const category = {
    state: () => ({
        categorys:[],
        category:{},
        countCategory:0
    }),
    getters:{
        countCategory(state){
            return state.countCategory
        },
        categorys(state){
            return state.categorys
        },
        category(state){
            return state.category
        },
        activeCategorys(state){
            return state.categorys.filter(category => category.status == 0)
        }
    },
    mutations:{
        countCategory(state,payload){
            state.countCategory = payload
        },
        categorys(state,payload){
            state.categorys = payload
        },
        category(state,payload){
            state.category = payload
        },
        newCategory(state,payload){
            state.categorys = [payload,...state.categorys]
            state.countCategory += 1
        },
        saveCategory(state,payload){
            let index = state.categorys.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.categorys[index] = payload
            }
        },
        deleteCategory(state,payload){
            state.categorys = state.categorys.filter(category => {
                if (category._id == payload) return false
                return category
            })
            state.countCategory -= 1
        }
    },
    actions:{
        async getCategorys(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'category',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('categorys',res.data)
            }
        },
        async getCountCategorys(context,payload){
            let res = await context.dispatch('getAxios',{url:'category/count',search:payload})
            if (res.status == 200){
                context.commit('countCategory',res.data.count)
            }
        },
        async changeStatusCategory(context,payload){
            let res = await context.dispatch('getAxios',{url:`category/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deleteCategory',res.data._id)
                    ElMessage({
                    message: 'Ta`lim turi o`chirildi',
                        type: 'warning',
                    })
                } else {
                    context.commit('saveCategory',res.data)
                    ElMessage({
                    message: 'Ta`lim turi holati o`zgartirildi',
                        type: 'success',
                    })

                }
            }
        },
        async editCategory(context,payload){
            return await context.dispatch('getAxios',{url:`category/${payload}`})
        },
        async saveCategory(context,payload){
            let res = await context.dispatch('putAxios',{url:`category`,data: payload})
            
            if (res.status == 200){
                context.commit('saveCategory',res.data)
                ElMessage({
                    message: 'Ta`lim turi yangilandi',
                    type: 'success',
                })
            }
        },
        async addCategory(context,payload){
            let res = await context.dispatch('postAxios',{url:'category',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi ta`lim turi qo`shildi',
                    type: 'success',
                })
                context.commit('newCategory',res.data)
            }
        },
    }
}