import cookie from "vue-cookies";
import router from "@/router";
import { ElMessage } from "element-plus";

import { adminSettings } from "@/router/admin";
import { bossSettings } from "@/router/boss";

export const auth = {
  state: () => ({
    user: {
      name: "",
      phone: "",
    },
    username: "",
    token: "",
    header: {},
    role: "",
    settingsLinks: [...adminSettings, ...bossSettings],
  }),
  getters: {
    settingsLinks(state) {
      return state.settingsLinks.filter((setting) =>
        setting.meta.role.includes(state.role)
      );
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    header(state) {
      return state.header;
    },
    role(state) {
      return state.role;
    },
    username(state) {
      return state.username;
    },
  },
  mutations: {
    role(state, payload) {
      state.role = payload;
    },
    user(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    header(state, payload) {
      state.header = payload;
    },
    username(state, payload) {
      state.username = payload;
    },
  },
  actions: {
    async reg({ dispatch, commit }, payload) {
      let res = await dispatch("postAxios", { url: "auth/reg", data: payload });
      if (res.status == 200) {
        let { token, user } = res.data;
        commit("user", user);
        commit("username", user.name);
        commit("setToken", token);
        dispatch("setLayout", `${user.role}-layout`);
        router.push("/");
        commit("role", user.role);
        commit("header", {
          authorization: `Bearer ${token}`,
        });
        cookie.set("quite-user", {
          user: user._id,
          token: token,
        });
      }
    },
    async loginTg({ dispatch, commit }, payload) {
      let res = await dispatch("postAxios", {
        url: "auth/logintg",
        data: payload,
      });
      if (res.status == 200) {
        let { token, user } = res.data;
        commit("user", user);
        commit("username", user.name);
        commit("setToken", token);
        dispatch("setLayout", `${user.role}-layout`);
        commit("role", user.role);
        commit("header", {
          authorization: `Bearer ${token}`,
        });
        cookie.set("quite-user", {
          user: user._id,
          token: token,
        });
        router.push("/");
      }
    },
    login(context, payload) {
      context
        .dispatch("postAxios", { url: "auth/login", data: payload })
        .then((res) => {
          if (res.status == 200) {
            let { token, user } = res.data;
            context.commit("user", user);
            context.commit("username", user.name);
            context.commit("setToken", token);
            context.dispatch("setLayout", `${user.role}-layout`);
            context.commit("role", user.role);
            context.commit("header", {
              authorization: `Bearer ${token}`,
            });
            cookie.set("quite-user", {
              user: user._id,
              token: token,
            });
            router.push("/");
          }
        })
        .catch((err) => {
          let { response } = err;
          cookie.remove("quite-user");
          ElMessage({
            message: response.data.message,
            type: "warning",
          });
        });
    },
    checkingCode(context, payload) {
      context
        .dispatch("postAxios", { url: "auth/checkcode", data: payload })
        .then((res) => {
          if (res.status == 200) {
            let { token, user } = res.data;
            // console.log(res.data);
            context.commit("user", user);
            context.commit("username", user.name);
            context.commit("setToken", token);
            context.dispatch("setLayout", `${user.role}-layout`);
            context.commit("role", user.role);
            context.commit("header", {
              authorization: `Bearer ${token}`,
            });
            cookie.set("quite-user", {
              user: user._id,
              token: token,
            });
            router.push("/");
          }
        })
        .catch((err) => {
          let { response } = err;
          cookie.remove("quite-user");
          ElMessage({
            message: response.data.message,
            type: "warning",
          });
        });
    },
    checkActive({ dispatch }, payload) {
      return dispatch("getAxios", { url: "auth/login", search: payload });
    },
    checkUser(context, payload) {
      let { token } = payload;
      context.commit("setToken", token);
      context.commit("header", {
        authorization: `Bearer ${token}`,
      });
      context
        .dispatch("getAxios", { url: "auth/checkuser" })
        .then((res) => {
          if (res.status == 200) {
            context.commit("user", res.data);
            context.commit("username", res.data.name);
            context.commit("role", res.data.role);
            context.commit("layout", `${res.data.role}-layout`);
            if (router.currentRoute.value.name == "login") router.push("/");
          } else {
            cookie.remove("quite-user");
            context.commit("layout", "auth-layout");
          }
        })
        .catch((e) => {
          console.log(e);
          cookie.remove("quite-user");
          context.commit("layout", "auth-layout");
          router.push("/login");
          ElMessage({
            message: "Serverda xatolik",
            type: "error",
          });
        });
    },
    exit(context) {
      cookie.remove("quite-user");
      context.commit("layout", "auth-layout");
      context.commit("user", {});
      context.commit("setToken", "");
      context.commit("role", "");
      ElMessage({
        message: "Tizimdan chiqdingiz",
        type: "warning",
      });
      router.push("/login");
    },
    checkLogin(context, payload) {
      return context.dispatch(
        "postAxios",
        { url: `auth/checkphone`, data: payload },
        { headers: context.getters.header }
      );
    },
    checkPhone({ dispatch, getters }, payload) {
      return dispatch(
        "postAxios",
        { url: `auth/checkreception`, data: payload },
        { headers: getters.header }
      );
    },
    restoreCode({ dispatch, getters }, payload) {
      return dispatch(
        "postAxios",
        { url: `auth/upcode`, data: payload },
        { headers: getters.header }
      );
    },
    checkEmail(context, payload) {
      return context.dispatch(
        "postAxios",
        { url: `auth/checkemail`, data: payload },
        { headers: context.getters.header }
      );
    },
    getProfile(context) {
      return context.dispatch("getAxios", { url: "auth/my" });
    },
    resetPass({ dispatch }, payload) {
      return dispatch("postAxios", { url: "bot", data: payload });
    },
    saveProfile(context, payload) {
      context
        .dispatch(`putAxios`, { url: "auth/save", data: payload })
        .then((res) => {
          if (res.status == 200) {
            context.commit("user", res.data);
            ElMessage({
              message: "Ma`lumotlar yangilandi",
              type: "success",
            });
            router.push("/");
          }
        });
    },
    upDocument(context, payload) {
      context
        .dispatch(`putAxios`, { url: "auth/update", data: payload })
        .then((res) => {
          if (res.status == 200) {
            context.dispatch("messagesForMe");
            ElMessage({
              message: "Ma`lumotlar yangilandi",
              type: "success",
            });
          }
        });
    },
  },
};
