import router from '@/router';
import { ElMessage } from 'element-plus';
import { addZero } from '@/store/modules/helpers';
export const message = {
  state: () => ({
    messages: [],
    message: {},
    countMessage: 0,
    messageTitle: 'Xabarlar',
    messagesTypes: [
      'Oddiy xabar',
      'So`rovnomali xabar',
      // 'Javobtalab xabar'
    ],
    messageAnswer: {
      result:[]
    },
    mymessages: [],
  }),
  getters: {
    mymessages({ mymessages }) {
      return mymessages;
    },
    messageAnswer({ messageAnswer }) {
      return messageAnswer;
    },
    messagesTypes({ messagesTypes }) {
      return messagesTypes;
    },
    countMessage(state) {
      return state.countMessage;
    },
    messageTitle({ messageTitle }) {
      return messageTitle;
    },
    messages(state) {
      return state.messages;
    },
    message(state) {
      return state.message;
    },
    activeMessages(state) {
      return state.messages.filter((message) => message.status == 0);
    },
  },
  mutations: {
    countMessage(state, payload) {
      state.countMessage = payload;
    },
    messages(state, payload) {
      let rolesList = {
        director: 'Direktor',
        rector: 'Rektor',
        support: 'Talabalar bo`limi',
        teacher: 'O`qituvchi',
        control: 'O`quv bo`limi',
        student: 'Talaba',
        olympian: 'Olimpiadist',
        reception: 'Qabul bo`limi',
        accountant: 'Buxgalteriya',
        testers: 'Sinovchilar',
      };
      state.messages = payload.map((message) => {
        message.who = [];
        if (message.role?.length > 0) {
          message.role?.forEach((role) => {
            if (role.role) {
              message.who.push(rolesList[role.role]);
            }
          });
        }
        let dateStart = new Date(message.createdAt);

        message.createdAt = `${addZero(dateStart.getHours())}:${addZero(
          dateStart.getMinutes()
        )} 
        ${addZero(dateStart.getDate())}/${addZero(
          dateStart.getMonth() + 1
        )}/${addZero(dateStart.getFullYear())}`;
        return message;
      });
    },
    message(state, payload) {
      state.message = payload;
    },
    newMessage(state, payload) {
      state.messages = [payload, ...state.messages];
      state.countMessage += 1;
    },
    saveMessage(state, payload) {
      let index = state.messages.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.messages[index] = payload;
      }
    },
    deleteMessage(state, payload) {
      state.messages = state.messages.filter((message) => {
        if (message._id == payload) return false;
        return message;
      });
      state.countMessage -= 1;
    },
    messageAnswers(state, payload) {
      state.messageAnswer = payload;
      state.messageAnswer.result = payload.result.map((answers) => {
        if (answers.content?.updateAt) {
          let dateStart = new Date(answers.content.updateAt);
          answers.content.updateAt = `${addZero(
            dateStart.getHours()
          )}:${addZero(dateStart.getMinutes())} ${addZero(dateStart.getDate())}/${addZero(
            dateStart.getMonth() + 1
          )}/${addZero(dateStart.getFullYear())}`;
        }
        if (answers.content?.createdAt) {
          let dateStart = new Date(answers.content.createdAt);
          answers.content.createdAt = `${addZero(
            dateStart.getHours()
          )}:${addZero(dateStart.getMinutes())} ${addZero(dateStart.getDate())}/${addZero(
            dateStart.getMonth() + 1
          )}/${addZero(dateStart.getFullYear())}`;
        }
        return answers;
      });
      // state.messageAnswer.result = state.messageAnswer.result.filter(msg => !msg.content)
      // .filter(msg => msg.result.content))
    },
    mymessages(state, payload) {
      state.mymessages = payload.map((msg) => {
        let dateStart = new Date(msg.message.createdAt);
        msg.message.createdAt = `${addZero(dateStart.getHours())}:${addZero(
          dateStart.getMinutes()
        )} 
              ${addZero(dateStart.getDate())}/${addZero(
          dateStart.getMonth() + 1
        )}/${addZero(dateStart.getFullYear())}`;
        return msg;
      });
    },
    changeAnswerMsg(state,payload){
      state.messageAnswer.result = state.messageAnswer.result.map(r => {
          if (r.content._id === payload._id) {
            r.content.answer = payload.answer
          }
        return r
      })
    }
  },
  actions: {
    async changeAnswer({commit,dispatch},payload){
      let res = await dispatch('getAxios',{
        url: `message/answer/${payload._id}/${payload.answer}`
      })
      if (res.status === 200){
        commit('changeAnswerMsg',res.data)
      }
    },
    async getMessages({ commit, dispatch }, payload) {
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: 'message',
        search: payload,
      });
      // if (res.status == 200) {
        // console.log(res.data)
        commit('loading', false);
        commit('messages', res.data);
      // }
    },
    async deleteFileMessage({ dispatch }, payload) {
      return dispatch('postAxios', {
        url: 'message/files/delete',
        data: payload,
      });
    },
    async getCountMessages({ commit, dispatch }, payload) {
      let res = await dispatch('getAxios', {
        url: 'message/count',
        search: payload,
      });
      if (res.status == 200) {
        commit('countMessage', res.data.count);
      }
    },
    async changeStatusMessage({ dispatch, commit, getters }, payload) {
      let res = await dispatch('getAxios', {
        url: `message/change/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          commit('deleteMessage', res.data._id);
          ElMessage({
            message: `${getters.messageTitle} o'chirildi`,
            type: 'warning',
          });
        } else {
          commit('saveMessage', res.data);
          ElMessage({
            message: `${getters.messageTitle} holati o'zgartirildi`,
            type: 'success',
          });
        }
      }
    },
    async sendMessage({ dispatch }, payload) {
      let res = await dispatch('getAxios', {
        url: `message/send/${payload}`,
      });
      if (res.status == 200) {
        ElMessage({
          message: 'Xabar jo`natildi',
          type: 'success',
        });
      }
    },
    async editMessage(context, payload) {
      return await context.dispatch('getAxios', {
        url: `message/${payload}`,
      });
    },
    async saveMessage(context, payload) {
      let res = await context.dispatch('putAxios', {
        url: `message`,
        data: payload,
      });

      if (res.status == 200) {
        context.commit('saveMessage', res.data.message);
        ElMessage({
          message: `${context.getters.messageTitle} yangilandi`,
          type: 'success',
        });
      }
    },
    async changeView({ dispatch, getters }, payload) {
      // console.log(payload)
      let res = await dispatch('getAxios', {
        url: `content/view/${payload.id}/${payload.status}`,
      });
      if (res.status == 200) {
        dispatch('showMessage',payload.msg);
        ElMessage({
          message: `${getters.messageTitle} holati o'zgartirildi`,
          type: 'success',
        });
      }
    },
    async showMessage({ dispatch, commit }, payload) {
      // console.log(payload);
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: `message/show/${payload}`,
      });
      
        // console.log(res.data);
        commit('messageAnswers', res.data);
        commit('loading', false);
        // res.data.filter(msg => msg.result.content)
      
    },
    async addMessage(context, payload) {
      let res = await context.dispatch('postAxios', {
        url: 'message',
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: `Yangi ${context.state.messageTitle.toLowerCase()} qo'shildi`,
          type: 'success',
        });
        router.push('/message');
      }
    },
    async messagesForMe({ dispatch, commit }) {
      let res = await dispatch('getAxios', {
        url: 'message/my',
      });
      if (res.status == 200) {
        commit('mymessages', res.data);
      }
    },
    
  },
};
