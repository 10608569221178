import { ElMessage } from 'element-plus'

export const nationality = {
    state: () => ({
        nationalities:[],
        nationality:{},
        countNationality:0
    }),
    getters:{
        countNationality(state){
            return state.countNationality
        },
        nationalities(state){
            return state.nationalities
        },
        nationality(state){
            return state.nationality
        },
        activeNationalities(state){
            return state.nationalities.filter(nationality => nationality.status == 0)
        }
    },
    mutations:{
        countNationality(state,payload){
            state.countNationality = payload
        },
        nationalities(state,payload){
            state.nationalities = payload
        },
        nationality(state,payload){
            state.nationality = payload
        },
        newNationality(state,payload){
            state.nationalities = [payload,...state.nationalities]
            state.countNationality += 1
        },
        saveNationality(state,payload){
            let index = state.nationalities.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.nationalities[index] = payload
            }
        },
        deleteNationality(state,payload){
            state.nationalities = state.nationalities.filter(nationality => {
                if (nationality._id == payload) return false
                return nationality
            })
            state.countNationality -= 1
        }
    },
    actions:{
        
        
        async getNationalities(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'nationality',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('nationalities',res.data)
            }
        },
        async getCountNationalities(context,payload){
            let res = await context.dispatch('getAxios',{url:'nationality/count',search:payload})
            if (res.status == 200){
                context.commit('countNationality',res.data.count)
            }
        },
        async changeStatusNationality(context,payload){
            let res = await context.dispatch('getAxios',{url:`nationality/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deleteNationality',res.data._id)
                    ElMessage({
                    message: 'Millat o`chirildi',
                        type: 'warning',
                    })
                } else {
                    context.commit('saveNationality',res.data)
                    ElMessage({
                    message: 'Millat holati o`zgartirildi',
                        type: 'success',
                    })

                }
            }
        },
        async editNationality(context,payload){
            return await context.dispatch('getAxios',{url:`nationality/${payload}`})
        },
        async saveNationality(context,payload){
            let res = await context.dispatch('putAxios',{url:`nationality`,data: payload})
            
            if (res.status == 200){
                context.commit('saveNationality',res.data)
                ElMessage({
                    message: 'Millat yangilandi',
                    type: 'success',
                })
            }
        },
        async addNationality(context,payload){
            let res = await context.dispatch('postAxios',{url:'nationality',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi millat qo`shildi',
                    type: 'success',
                })
                context.commit('newNationality',res.data)
            }
        },
        
    }
}