<template>
    <el-table stripe show-summary v-loading="loading" :summary-method="getSummaries" :data="data">
        <el-table-column type="expand">
            <template #default="props">
                <div class="stat">
                    <el-table :data="props.row.city">
                    <el-table-column type="index" width="60"/>
                    <el-table-column label="Shahar/Tuman" prop="name" />
                    <el-table-column label="Ishtirokchilar soni" prop="statistic" />
                </el-table>
                </div>
            </template>
            </el-table-column>
        <el-table-column type="index" width="60"/>
        <el-table-column label="Viloyat" sortable prop="name"/>
        <el-table-column label="Ishtirokchilar soni" sortable prop="statistic"/>
        
    </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: ['data'],
    computed:{
        ...mapGetters([
            'loading'
        ])
    },
    methods:{
        getSummaries(param){
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = 'Jami'
                    return
                }
                const values = data.map((item) => Number(item[column.property]))
                if (!values.every((value) => Number.isNaN(value))) {
                    sums[index] = `${values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!Number.isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)}`
                } else {
                    sums[index] = ''
                }
            })

            return sums
        }
    }
}
</script>

<style>
.stat {
    padding: 10px 30px;
}
</style>