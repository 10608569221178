<template>
    <section :class="`main pd ${dark ? 'dark' : ''}`">
        <el-header />
        <section class="wrapper">
            <el-aside :menu="menu" />
            <el-main id="main" v-loading="loading">
                <router-view />
            </el-main>
        </section>
    </section>
</template>

<script>
import ElHeader from '@/components/ElHeader.vue'
import ElAside from '@/components/ElAside.vue'
import { mapGetters } from 'vuex'

import { studentMenu } from '@/router/student'

export default {
    data: () => ({

        menu: [
            {
                path: '/',
                icon: 'house',
                title: 'Bosh sahifa'
            },
            ...studentMenu,
        ]
    }),
    components: {
        ElAside,
        ElHeader
    },
    computed: {
        ...mapGetters([
            'loading',
            'dark'
        ])
    },
    watch: {
        $route() {
            document.getElementById('main').scrollTo(0, 0)
        }
    }
}
</script>

<style>

</style>