<template>
    
        <h3 class="mb-20">Viloyat bo'yicha tanlovlar statistikasi</h3>
        <by-region :data="statistic" />
    
</template>

<script>
import ByRegion from '@/components/statistic/ByRegion.vue';

import { mapActions, mapGetters } from 'vuex'
export default {
    components:{
        ByRegion
    },
    computed: {
        ...mapGetters([
            'statistic'
        ])
    },
    methods:{
        ...mapActions([
            'getStatistics'
        ])
    },
    mounted(){
        this.getStatistics()
    }
}
</script>

<style>

</style>