<template>
  
  <div class="home__full">
    <el-row :gutter="30" class="home__height">
      <el-col :span="mymessages.length ? 16 : 24" :xs="24" class="home__height">
        <div class="proccess box" v-if="testproccesss.length > 0">
          <h3>Sinovlar ro'yhati</h3>
          <el-row :gutter="30">
            <el-col
              :lg="8"
              :md="8"
              :xs="24"
              v-for="(proccess, index) of testproccesss"
              :key="proccess._id"
              class="mb-20 home__height"
            >
              <div class="card" @click="showTestproccess(index)">
                <img :src="`${url}/${proccess.img}`" alt="" />
                <div class="title">{{ proccess.title }}</div>
                <div class="card__row">
                  <div>
                    {{
                      proccess.language == "ru"
                        ? "Время начало:"
                        : "Boshlanish vaqti:"
                    }}
                    <span>{{ proccess.start }}</span>
                  </div>
                  <div>
                    {{
                      proccess.language == "ru"
                        ? "Время окончание:"
                        : "Tugash muddati:"
                    }}

                    <span>{{ proccess.end }}</span>
                  </div>
                  <div>
                    {{
                      proccess.language == "ru"
                        ? "Количество тестов:"
                        : "Umumiy testlar soni:"
                    }}

                    <span
                      >{{ proccess.testsCount }}
                      {{ proccess.language == "ru" ? "шт." : "ta" }}
                    </span>
                  </div>
                  <div>
                    {{
                      proccess.language == "ru"
                        ? "Количество попыток"
                        : "Urinishlar soni"
                    }}
                    <span>
                      <span>{{ proccess.trycount }} / </span>
                      <span>{{ proccess.testpack.trycount }} </span>
                    </span>
                  </div>
                  <div class="df align-items-center justify-content-between">
                    {{ proccess.language == "ru" ? "Статус" : "Holat" }}
                    <el-tag type="warning" v-if="proccess.earl">
                      {{
                        proccess.language == "ru"
                          ? "Не началось"
                          : "Hali boshlanmadi"
                      }}
                    </el-tag>
                    <el-tag type="danger" v-if="proccess.late && proccess.end">
                      {{
                        proccess.language == "ru" ? "Закончилось" : "Tugagan"
                      }}
                    </el-tag>
                    <el-tag type="success" v-if="proccess.open && proccess.end">
                      {{ proccess.language == "ru" ? "Октрытий" : "Ochiq" }}
                    </el-tag>
                  </div>
                  <div class="df align-items-center">
                    {{ proccess.language == "ru" ? "Результат:" : "Natija:" }}

                    <span
                      :class="`proccess__status ${
                        proccess.result ? 'success' : 'warning'
                      }`"
                      >{{
                        proccess.result
                          ? proccess.result.balls >= 0
                            ? proccess.result.balls +
                              " ball (" +
                              (
                                (proccess.result.balls /
                                  proccess.result.allballs) *
                                100
                              ).toFixed(2) +
                              " %)"
                            : proccess.language == "ru"
                            ? "В процессе"
                            : "Jarayonda"
                          : proccess.language == "ru"
                          ? "Не участвовал!"
                          : "Ishtirok etilmagan!"
                      }}</span
                    >
                  </div>
                </div>
                <div class="proccess__more">
                  {{ proccess.language == "ru" ? "Участвовать" : "Topshirish" }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-dialog v-model="toggle" class="proccess__show" width="40%" center>
            <img :src="`${url}/${process.img}`" alt="" />
            <div class="proccess__body">
              <div class="proccess__text">
                <div class="title">{{ process.title }}</div>
                <el-tag
                  v-if="process.ball > 0 && process.allballs > 0"
                  type="success"
                  size="large"
                  effect="dark"
                >
                  {{ process.language == "ru" ? "Результат" : "Natija" }}
                  : {{ process.results.balls.toFixed(2) }} ball ({{
                    Math.round(process.ball)
                  }}%)
                </el-tag>
                <el-tag type="warning" v-if="process.earl">
                  {{
                    process.language == "ru"
                      ? "Не началось"
                      : "Hali boshlanmadi"
                  }}
                </el-tag>
                <el-tag type="danger" v-if="process.late && process.ball == 0">
                  {{ process.language == "ru" ? "Закончилось" : "Tugagan" }}
                </el-tag>
                <div class="proccess__row mt-10">
                  <div>
                    {{
                      process.language == "ru"
                        ? "Время начало:"
                        : "Boshlanish vaqti:"
                    }}
                    <span>{{ process.start }}</span>
                  </div>
                  <div>
                    {{
                      process.language == "ru"
                        ? "Время окончание:"
                        : "Tugash muddati:"
                    }}
                    <span>{{ process.end }}</span>
                  </div>
                  <div>
                    {{
                      process.language == "ru"
                        ? "Количество тестов:"
                        : "Umumiy testlar soni:"
                    }}
                    <span>
                      {{ process.testsCount }}
                      {{ process.language == "ru" ? "шт." : "ta" }}
                    </span>
                  </div>
                </div>
                <div class="text" v-html="process.text" />
                <el-button
                  type="success"
                  class="mt-10"
                  @click="start(process._id)"
                  v-if="
                    process.trycount <= process.testpack.trycount &&
                    process.result.endtrycount < process.testpack.trycount &&
                    process.open
                  "
                >
                  {{
                    process.language == "ru" ? "Начать тест" : "Testni boshlash"
                  }}
                </el-button>
                <div class="mt-20">
                  
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
        <div
          v-else
          class="infozone df align-items-center justify-content-center"
        >
          <img src="@/assets/files.svg" alt="" class="mb-20" />
          <h2 class="mb-20">Xozirda Olimpiadalar mavjud emas.</h2>
        </div>
      </el-col>
      <el-col
        :span="8"
        :xs="24"
        v-if="mymessages.length > 0"
        class="home__height"
      >
        <div class="proccess box list" v-if="mymessages.length > 0">
          <h3>Xabarlar</h3>
          <el-row :gutter="30">
            <el-col
              :lg="24"
              :md="24"
              :xs="24"
              v-for="message of mymessages"
              :key="message._id"
              class="mb-20 home__height"
            >
              <div class="card nopoint">
                <img
                  :src="`${url}/${message.message.img}`"
                  alt=""
                  class="noheight"
                />
                <div class="title">{{ message.message.title }}</div>
                <div class="date">
                  <el-icon><calendar /></el-icon>
                  {{ message.message.createdAt }}
                </div>
                <div class="text" v-html="message.message.text"></div>
                <el-button
                  @click="addDocument(message.message)"
                  class="proccess__more"
                  v-if="message.message.type == 1 && message.view == 0"
                  >Tasdiqlash</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-dialog v-model="documentToggle" width="40%" center>
      <div class="document">
        <h1 class="mb-10">{{ me.title }}</h1>
        <div v-html="me.text" class="mb-10 normtext"></div>
        <el-radio-group v-model="me.answer">
          <el-radio label="yo`q" size="large" border>YO'Q</el-radio>
          <el-radio label="ha" size="large" border>HA</el-radio>
        </el-radio-group>
        <div v-if="me.answer == 'ha'">
          <el-divider />
          <p class="mb-10 normtext">
            ID karta (pasport) yoki tug’ilganlik guvohnomasining seriya va
            raqamini kiriting
          </p>
          <el-input v-model="me.passport" />

          <div class="title mt-20"></div>
          <p><b class="mb-10">O`zingizni rasmingizni yuklang</b></p>
          <el-upload
            list-type="picture-card"
            :before-upload="beforeUpload"
            :action="`${url}/auth/upload`"
            :on-remove="handleRemove"
            :file-list="me.files"
            :on-success="handleSuccess"
          >
            <el-icon><Plus /></el-icon>
            <template #file="{ file }">
              <div>
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="previewPhoto"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <el-icon><zoom-in /></el-icon>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <el-icon><Download /></el-icon>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <el-icon><Delete /></el-icon>
                  </span>
                </span>
              </div>
            </template>
          </el-upload>
          <p class="mt-20"></p>
          <p class="mb-10">
            <b
              >ID karta (pasport) yoki tug’ilganlik guvohnomasi rasmini yuklang
            </b>
          </p>
          <el-upload
            list-type="picture-card"
            :before-upload="beforeUpload"
            :action="`${url}/auth/passport`"
            :on-remove="handleRemovePassport"
            :file-list="me.passports"
            :on-success="handleSuccessPassport"
          >
            <el-icon><Plus /></el-icon>
            <template #file="{ file }">
              <div>
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="previewPassport"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <el-icon><zoom-in /></el-icon>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <el-icon><Download /></el-icon>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemovePassport(file)"
                  >
                    <el-icon><Delete /></el-icon>
                  </span>
                </span>
              </div>
            </template>
          </el-upload>
        </div>

        <el-input
          v-if="me.answer == 'yo`q'"
          v-model="me.comment"
          :rows="4"
          class="mt-10"
          type="textarea"
          placeholder="Sababini yozing"
        />
        <el-divider />
        <el-alert :title="errormsg" type="error" v-if="errormsg" />

        <el-button
          type="success"
          class="mt-10"
          @click="updateDocument"
          :disabled="!user.agree"
          >Saqlash</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    toggle: false,
    process: {},
    documentToggle: false,
    me: {
      passports: [],
      files: [],
    },
    previewPhoto: "",
    previewPassport: "",
    errormsg: "",
  }),
  computed: {
    ...mapGetters(["user", "testproccesss", "url", "loading", "mymessages"]),
  },
  methods: {
    ...mapActions([
      "getTestproccesssOlympic",
      "startPushResult",
      "messagesForMe",
      "upDocument",
    ]),
    addDocument(msg) {
      this.me = {
        title: msg.title,
        text: msg.text,
        passports: [],
        files: [],
        message: msg._id,
      };
      this.documentToggle = true;
    },
    updateDocument() {
      if (this.me.answer == "ha") {
        if (this.me.avatar && this.me.passportFile && this.me.passport) {
          this.me.answer = "yes";
          this.upDocument(this.me);
          this.messagesForMe();
          this.documentToggle = false;
        } else {
          this.errormsg = "Barcha maydonlarni to`ldiring";
        }
      }
      if (this.me.answer == "yo`q") {
        this.me.answer = "no";
        this.upDocument(this.me);
        this.messagesForMe();
        this.documentToggle = false;
      }
    },
    async handleRemove() {
      this.previewPhoto = "";
      this.me.files = [];
    },
    async handleRemovePassport() {
      this.passports = "";
      this.me.passports = [];
    },
    handleSuccess(file) {
      this.previewPhoto = `${this.url}/${file}`;
      this.me.avatar = "/" + file;
      this.me.files.push(file);
    },
    handleSuccessPassport(file) {
      this.previewPassport = `${this.url}/${file}`;
      this.me.passportFile = "/" + file;
      this.me.passports.push(file);
    },
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 5) {
        this.fileError = true;
        return false;
      } else {
        this.fileError = false;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    showTestproccess(index) {
      this.process.allballs = 0;
      this.process = { ...this.testproccesss[index] };

      if (this.process.result) {
        this.process.allballs = this.process.result.allballs;
        this.process.balls = this.process.result.balls;
      } else {
        this.process.result = {
          endtrycount: 0,
        };
      }

      this.toggle = true;
    },
    start(id, trycount) {
      // if (!this.user.agree) return false;
      this.startPushResult({ procid: id, check: 1, trycount });
      // this.$router.push(`/quiz/${id}`)
    },
  },
  async mounted() {
    await this.getTestproccesssOlympic();
    await this.messagesForMe();
  },
};
</script>

<style lang="scss">
@import "@/styles/view/home.scss";
@import "@/styles/view/testproccess.scss";
</style>
