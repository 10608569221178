export const olimpianMenu = [
  {
    path: '/results',
    icon: 'collection',
    name: 'results',
    title: 'Natijalar',
    component: () =>
      import(
        /* webpackChunkName: "results" */ '../views/olympian/ResultsPage.vue'
      ),
    meta: { role: ['olympian','testers'] },
  },
  {
    path: '/mycertificate',
    icon: 'collection',
    name: 'certificate',
    title: 'Sertifikatlar',
    component: () =>
      import(
        /* webpackChunkName: "certificate" */ '../views/olympian/CertficateList.vue'
      ),
    meta: { role: ['olympian','testers'] },
  },
  {
    path: '/agree',
    icon: 'document-copy',
    name: 'agree',
    title: 'Tanlov qoidalari',
    component: () =>
      import(
        /* webpackChunkName: "agree" */ '../views/olympian/AgreePage.vue'
      ),
    meta: { role: ['olympian','testers'] },
  },
];

export const olimpianLinks = [
  ...olimpianMenu
];
