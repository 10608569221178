import { ElMessage } from 'element-plus';

export const school = {
  state: () => ({
    schools: [],
    school: {},
    countSchool: 0,
  }),
  getters: {
    countSchool(state) {
      return state.countSchool;
    },
    schools(state) {
      return state.schools;
    },
    school(state) {
      return state.school;
    },
    activeSchools(state) {
      return state.schools.filter((school) => school.status == 0);
    },
  },
  mutations: {
    countSchool(state, payload) {
      state.countSchool = payload;
    },
    schools(state, payload) {
      state.schools = payload;
    },
    school(state, payload) {
      state.school = payload;
    },
    newSchool(state, payload) {
      state.schools = [...payload, ...state.schools];
      state.countSchool += payload.length;
    },
    saveSchool(state, payload) {
      let index = state.schools.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.schools[index] = payload;
      }
    },
    deleteSchool(state, payload) {
      state.schools = state.schools.filter((school) => {
        if (school._id == payload) return false;
        return school;
      });
      state.countSchool -= 1;
    },
  },
  actions: {
    async getSchools(context, payload) {
      context.commit('loading', true);
      let res = await context.dispatch('getAxios', {
        url: 'school',
        search: payload,
      });
      if (res.status == 200) {
        context.commit('loading', false);
        context.commit('schools', res.data);
      }
    },
    async transferSchool({dispatch,commit},payload){
      let res = await dispatch('getAxios',{url:`litsey/school/${payload}`})
      if (res.status == 200){
        commit('deleteSchool',payload)
        ElMessage({
          message: 'Maktab Litseyga  ko`chirildi',
          type: 'warning',
        });
      }
    },
    async getCountSchools(context, payload) {
      let res = await context.dispatch('getAxios', {
        url: 'school/count',
        search: payload,
      });
      if (res.status == 200) {
        context.commit('countSchool', res.data.count);
      }
    },
    async changeStatusSchool(context, payload) {
      let res = await context.dispatch('getAxios', {
        url: `school/status/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          context.commit('deleteSchool', res.data._id);
          ElMessage({
            message: 'Maktab o`chirildi',
            type: 'warning',
          });
        } else {
          context.commit('saveSchool', res.data);
          ElMessage({
            message: 'Maktab holati o`zgartirildi',
            type: 'success',
          });
        }
      }
    },
    async editSchool(context, payload) {
      return await context.dispatch('getAxios', { url: `school/${payload}` });
    },
    async saveSchool(context, payload) {
      let res = await context.dispatch('putAxios', {
        url: `school`,
        data: payload,
      });

      if (res.status == 200) {
        context.commit('saveSchool', res.data);
        ElMessage({
          message: 'Maktab yangilandi',
          type: 'success',
        });
      }
    },
    excelSchool({dispatch},payload){
      return dispatch('getAxios',{
        url: 'school/excel',
        search: payload,
      })
    },
    async addSchool(context, payload) {
      let res = await context.dispatch('postAxios', {
        url: 'school',
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: 'Yangi maktablar qo`shildi',
          type: 'success',
        });
        context.commit('newSchool', res.data);
      }
    },
    addSchools({dispatch},payload){
      return dispatch('postAxios',{
        url:'school',
        data: payload
      })
    }
  },
};
