<template>
    <section :class="`main pd ${dark ? 'dark' : ''}`">
        <el-header />
        <section class="wrapper">
            <el-aside :menu="menu" />
            <el-main id="main" v-loading="loading">
                <router-view />
            </el-main>
        </section>
    </section>
</template>
  
<script>
import ElHeader from '@/components/ElHeader.vue'
import ElAside from '@/components/ElAside.vue'
import { mapGetters } from 'vuex'
import { adminMenu } from '@/router/admin'
export default {
    data: () => ({
        menu: [
            {
                path: '/',
                icon: 'house',
                title: 'Bosh sahifa'
            },
            ...adminMenu.filter(menu => menu.meta?.role?.includes('teacher'))
        ]
    }),
    components: {
        ElAside,
        ElHeader
    },
    computed: {
        ...mapGetters([
            'loading',
            'dark',
            'user'
        ])
    },
    watch: {
        $route() {
            document.getElementById('main').scrollTo(0, 0)
        }
    }
}
</script>
  
<style></style>