export const history = {
    state: () => ({
        historyList:[],
        historyUser:{},
        historyMethod:{
            'POST': 'Ma`lumot qo`shildi',
            'PUT': 'Ma`lumot tahrirlandi',
            'DELETE': 'Ma`lumot o`chirildi'
        },
        historyUrl: {
            'group': 'guruh',
            'subjectcategory': 'fan turi',
            'student': 'talaba'
        }
    }),
    getters:{
        historyList(state){
            return state.historyList
        },
        historyUser(state){
            return state.historyUser
        }
    },
    mutations:{
        historySet(state,payload){
            state.historyList = payload.logger.map(log => {
                let date = new Date(log.timestamp)

                log.timestamp = `${date.getFullYear()}-${date.getMonth()<10?'0'+(date.getMonth()+1):date.getMonth()}-${date.getDate()<10?'0'+date.getDate():date.getDate()} ${date.getHours()<10?'0'+date.getHours():date.getHours()}:${date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()}`
                log.message = JSON.parse(log.message)
                log.type = ''
                if (log.message.method){
                    // console.log(log.message);
                    let {method} = log.message
                    log.type = state.historyMethod[log.message.method]
                    Object.keys(state.historyUrl).forEach(key=>{
                        if (method == 'POST' && log.message.url.indexOf(key) !==-1){
                            log.type = `Yangi ${state.historyUrl[key]} qo'shildi`
                            log.detail = ''
                            Object.keys(log.message.body).forEach(key => {
                                if (key.indexOf('_id')==-1 && key.indexOf('password')==-1 && key.indexOf('user_id')==-1){
                                    log.detail += log.message.body[key] + ' '
                                }
                            }) 
                        }
                        if (method == 'PUT' && log.message.url.indexOf(key) !==-1){
                            log.type = `${state.historyUrl[key]} tahrirlandi`
                            log.detail = 'Yangi: '
                            Object.keys(log.message.body).forEach(key => {
                                if (key.indexOf('_id')==-1 && key.indexOf('createdAt')==-1 && key.indexOf('status')==-1){
                                    log.detail += log.message.body[key] + ' '
                                }
                            }) 
                            if (log.message.oldInfo) {
                                log.detail += '<br>Eski: '
                                Object.keys(log.message.oldInfo).forEach(key => {
                                    
                                    if (key.indexOf('_id')==-1 && key.indexOf('createdAt')==-1 && key.indexOf('status')==-1){
                                        log.detail += log.message.oldInfo[key] + ' '
                                    }
                                })    
                            }

                            
                        }
                    })
                } else {
                    log.type = 'Tizimga kirish'
                }
                return log
            })
            // console.log(state.historyList)
            state.historyUser = payload.info
        }
    },
    actions:{
        async getHistoryList(context,payload){
            if (payload){
                
                context.commit('loading',true)
                let res = await context.dispatch('getAxios',{url:`logger/${payload}`})
                // console.log(res.data)
                if (res.status == 200){
                    context.commit('loading',false)
                    context.commit('historySet',res.data)
                }
            }
        }
    }
}