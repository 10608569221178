import { ElMessage } from 'element-plus';

export const room = {
  state: () => ({
    rooms: [],
    room: {},
    countRoom: 0,
    roomTitle: 'Xonalar',
  }),
  getters: {
    countRoom({countRoom}) {
      return countRoom;
    },
    rooms({rooms}) {
      return rooms;
    },
    room({room}) {
      return room;
    },
    activeRooms({rooms}) {
      return rooms.filter(room => room.status == 0);
    },
  },
  mutations: {
    countRoom(state, payload) {
      state.countRoom = payload;
    },
    rooms(state, payload) {
      state.rooms = payload;
    },
    room(state, payload) {
      state.room = payload;
    },
    newRoom(state, payload) {
      state.rooms = [payload, ...state.rooms];
      state.countRoom += 1;
    },
    saveRoom(state, payload) {
      let index = state.rooms.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.rooms[index] = payload;
      }
    },
    deleteRoom(state, payload) {
      state.rooms = state.rooms.filter((room) => {
        if (room._id == payload) return false;
        return room;
      });
      state.countRoom -= 1;
    },
  },
  actions: {
    async getRooms({dispatch,commit}, payload) {
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: 'room',
        search: payload,
      });
      if (res.status == 200) {
        // console.log(res.data)
        commit('loading', false);
        commit('rooms', res.data.room)
        commit('countRoom', res.data.count)
      }
    },
    async changeStatusRoom({dispatch,commit,state}, payload) {
      let res = await dispatch('getAxios', {
        url: `room/status/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          commit('deleteRoom', res.data._id);
          ElMessage({
            message: `${state.roomTitle} o'chirildi`,
            type: 'warning',
          });
        } else {
          commit('saveRoom', res.data);
          ElMessage({
            message: `${state.roomTitle} holati o'zgartirildi`,
            type: 'success',
          });
        }
      }
    },
    async editRoom({dispatch}, payload) {
      return await dispatch('getAxios', {
        url: `room/get/${payload}`,
      });
    },
    async saveRoom({dispatch,commit,getters}, payload) {
      let res = await dispatch('putAxios', {
        url: `room`,
        data: payload,
      });

      if (res.status == 200) {
        commit('saveRoom', res.data);
        ElMessage({
          message: `${getters.roomTitle} yangilandi`,
          type: 'success',
        });
      }
    },
    async addRoom({dispatch,state,commit}, payload) {
      let res = await dispatch('postAxios', {
        url: 'room',
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: `Yangi ${state.roomTitle.toLowerCase()} qo'shildi`,
          type: 'success',
        });
        commit('newRoom', res.data);
      }
    },
  },
};
