import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { vMaska } from "maska/vue";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import excel from "vue-excel-export";

const app = createApp(App)
  .directive("maska", vMaska)
  .use(ElementPlus)
  .use(excel)
  .use(VueCookies, { expire: "1d" })
  .use(store)
  .use(router);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount("#app");
