
import { layoutApp } from './modules/layout'
import { auth } from './modules/auth'
import { helpers } from './modules/helpers'

import { citizen } from './data/settings/citizen'
import { region } from './data/settings/region'
import { city } from './data/settings/city'
import { school } from './data/settings/school'
import { litsey } from './data/settings/litsey'
import { place } from './data/settings/place'
import { room } from './data/settings/room'

import { category } from './data/settings/category'
import { direction } from './data/settings/direction'

import { nationality } from './data/nationality'
import { social } from './data/social'
import { language } from './data/settings/language'
import { type } from './data/settings/type'

import { history } from './data/history'
import { result } from './data/result'
import { statistic } from './data/statistic'
import { certificate } from './data/certificate'
import { cerPack } from './data/cerPack'
import { final } from './data/boss/final'

import { subjectcategory } from './data/boss/subjectcategory'
import { subject } from './data/boss/subject,js'
import { theme } from './data/boss/theme'
import { testtype } from './data/boss/testtype'
import { teacher } from './data/boss/teacher'
import { group } from './data/boss/group'
import { student } from './data/boss/student'
import { test } from './data/boss/test'
import { testpack } from './data/boss/testpack'
import { testproccess } from './data/boss/testproccess'
import { message } from './data/message'

import { request } from './data/student/request'

import { user } from './data/user'


export const modulesList = {
    modules: {
        layoutApp,
        auth, helpers,

        // settings
        nationality, 
        social, 
        language,

        // history
        history,
        result,
        statistic,
        
        // boss settings
        citizen,region,city, 
        place, type,
        school, litsey,
        room, final,

        category, 
        direction,
        request, 
        message, 
        
        subjectcategory, 
        subject,
        theme,
        testtype,
        teacher,
        group,
        student, 
        test,
        testpack,
        testproccess,
        certificate,
        cerPack,
        
        // main data
        user
    }
}
