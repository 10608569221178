import { ElMessage } from 'element-plus'

export const citizen = {
    state: () => ({
        citizens:[],
        citizen:{},
        countCitizen:0
    }),
    getters:{
        countCitizen(state){
            return state.countCitizen
        },
        citizens(state){
            return state.citizens
        },
        citizen(state){
            return state.citizen
        },
        activeCitizens(state){
            return state.citizens.filter(citizen => citizen.status == 0)
        }
    },
    mutations:{
        countCitizen(state,payload){
            state.countCitizen = payload
        },
        citizens(state,payload){
            state.citizens = payload
        },
        citizen(state,payload){
            state.citizen = payload
        },
        newCitizen(state,payload){
            state.citizens = [payload,...state.citizens]
            state.countCitizen += 1
        },
        saveCitizen(state,payload){
            let index = state.citizens.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.citizens[index] = payload
            }
        },
        deleteCitizen(state,payload){
            state.citizens = state.citizens.filter(citizen => {
                if (citizen._id == payload) return false
                return citizen
            })
            state.countCitizen -= 1
        }
    },
    actions:{
        async getCitizens(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'citizen',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('citizens',res.data)
            }
        },
        async getCountCitizens(context,payload){
            let res = await context.dispatch('getAxios',{url:'citizen/count',search:payload})
            if (res.status == 200){
                context.commit('countCitizen',res.data.citizen)
            }
        },
        async changeStatusCitizen(context,payload){
            let res = await context.dispatch('getAxios',{url:`citizen/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deleteCitizen',res.data._id)
                    ElMessage({
                    message: 'Fuqarolik o`chirildi',
                        type: 'warning',
                    })
                } else {
                    context.commit('saveCitizen',res.data)
                    ElMessage({
                    message: 'Fuqarolik holati o`zgartirildi',
                        type: 'success',
                    })

                }
            }
        },
        async editCitizen(context,payload){
            return await context.dispatch('getAxios',{url:`citizen/${payload}`})
        },
        async saveCitizen(context,payload){
            let res = await context.dispatch('putAxios',{url:`citizen`,data: payload})
            
            if (res.status == 200){
                context.commit('saveCitizen',res.data)
                ElMessage({
                    message: 'Fuqarolik yangilandi',
                    type: 'success',
                })
            }
        },
        async addCitizen(context,payload){
            let res = await context.dispatch('postAxios',{url:'citizen',data: payload})
            
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi fuqarolik qo`shildi',
                    type: 'success',
                })
                context.commit('newCitizen',res.data)
            }
        },
    }
}