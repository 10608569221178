<template>
    <div class="box">
        <h1>Xush kelibsiz, {{ user.name }}</h1>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters([
            'user'
        ])
    }
}
</script>

<style>

</style>