import { createStore } from "vuex";

import { modulesList } from "./modules";

export default createStore({
  state: {
    url: "https://platform.umft.uz",
    // url: 'http://192.168.20.32:3005',
    langs: [
      {
        label: "O`zbek tili",
        language: "uz",
      },
      {
        label: "Rus tili",
        language: "ru",
      },
    ],
    months: [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ],
    loading: false,
    dark: false,
    status: ["Faol", "Faol emas"],
    weekdays: [
      "Dushanba",
      "Seshanba",
      "Chorshanba",
      "Payshanba",
      "Juma",
      "Shanba",
    ],
  },
  getters: {
    langs({ langs }) {
      return langs;
    },
    dark(state) {
      return state.dark;
    },
    weekdays(state) {
      return state.weekdays;
    },
    status(state) {
      return state.status;
    },
    loading(state) {
      return state.loading;
    },
    url(state) {
      return state.url;
    },
    uri(state) {
      return state.uri;
    },
    months(state) {
      return state.months;
    },
  },
  mutations: {
    dark(state, payload) {
      state.dark = payload;
    },
    loading(state, payload) {
      state.loading = payload;
    },
  },
  modules: {
    modulesList,
  },
});
