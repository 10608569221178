import { ElMessage } from "element-plus";
import { addZero } from "../modules/helpers";

export const cerPack = {
  state: () => ({
    cerPacks: [],
    certs: [],
    cerPack: {
      sertificates: [],
    },
    countCerPack: 0,
    cerPackTitle: "Sertifikat yaratish",
    cerpackconds: [
      "Test sinovdagi natija bo`yicha",
      // 'Barcha foydalanuvchilarga',
      // 'Ma`lum foydalanuvchi turkumlariga',
      // 'Ma`lum balldan kattalarga'
    ],
  }),
  getters: {
    certs({ certs }) {
      return certs;
    },
    cerpackconds({ cerpackconds }) {
      return cerpackconds;
    },
    countCerPack(state) {
      return state.countCerPack;
    },
    cerPacks(state) {
      return state.cerPacks;
    },
    cerPack(state) {
      return state.cerPack;
    },
    activeCerPacks(state) {
      return state.cerPacks.filter((cerPack) => cerPack.status == 0);
    },
  },
  mutations: {
    countCerPack(state, payload) {
      state.countCerPack = payload;
    },
    cerPacks(state, payload) {
      state.cerPacks = payload.map((cerpack) => {
        cerpack.title = "";
        if (cerpack.testprocess) {
          cerpack.title = `'${cerpack.testprocess.title}' test jarayon bo'yicha (${cerpack.min}-${cerpack.max} ball)`;
        }
        return cerpack;
      });
    },
    cerPack(state, payload) {
      state.cerPack = payload;
      state.cerPack.sertificates = state.cerPack.sertificates.map((sert) => {
        if (sert.createdAt) {
          let dateStart = new Date(sert.createdAt);
          sert.createdAt = `${addZero(dateStart.getHours())}:${addZero(
            dateStart.getMinutes()
          )} ${addZero(dateStart.getDate())}/${addZero(
            dateStart.getMonth() + 1
          )}/${addZero(dateStart.getFullYear())}`;
        }
        if (sert.history) {
          if (sert.history.at(-1)) {
            let dateStart = new Date(sert.history.at(-1));
            sert.his = `${addZero(dateStart.getHours())}:${addZero(
              dateStart.getMinutes()
            )} ${addZero(dateStart.getDate())}/${addZero(
              dateStart.getMonth() + 1
            )}/${addZero(dateStart.getFullYear())}`;
          }
        }
        return sert;
      });
    },
    newCerPack(state, payload) {
      let obj = payload;
      if (obj.testprocess) {
        obj.title = `'${obj.testprocess.title}' test jarayon bo'yicha (${obj.min}-${obj.max} ball)`;
      }
      state.cerPacks = [obj, ...state.cerPacks];
      state.countCerPack += 1;
    },
    saveCerPack(state, payload) {
      let index = state.cerPacks.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.cerPacks[index] = payload;
        state.cerPacks[
          index
        ].title = `'${state.cerPacks[index].testprocess.title}' test jarayon bo'yicha (${state.cerPacks[index].min}-${state.cerPacks[index].max} ball)`;
      }
    },
    deleteCerPack(state, payload) {
      state.cerPacks = state.cerPacks.filter((cerPack) => {
        if (cerPack._id == payload) return false;
        return cerPack;
      });
      state.countCerPack -= 1;
    },
    certs(state, payload) {
      state.certs = payload;
    },
  },
  actions: {
    async getCerPacks(context, payload) {
      context.commit("loading", true);
      let res = await context.dispatch("getAxios", {
        url: "cerPack",
        search: payload,
      });

      context.commit("loading", false);
      context.commit("cerPacks", res.data.cerPack);
      context.commit("countCerPack", res.data.cerPackCount);
    },
    async deleteFileCerpack({ dispatch }, payload) {
      return dispatch("postAxios", {
        url: "cerpack/files/delete",
        data: payload,
      });
    },
    async getMyCerPacks({ dispatch, commit }) {
      let res = await dispatch("getAxios", {
        url: "cerPack/getcer",
      });
      if (res.status) {
        commit("cerPacks", res.data);
      }
    },
    async generateByCertPack({ dispatch }, payload) {
      let res = await dispatch("getAxios", {
        url: `certificate/create/${payload}`,
      });
      if (res.status == 200) {
        ElMessage({
          message: `Setifikatlar generatsiya qilindi!`,
          cerPack: "success",
        });
      }
    },
    downloadCerPack({ dispatch }, payload) {
      return dispatch("postAxios", {
        url: `cerPack`,
        data: payload,
      });
    },
    async changeStatusCerPack(context, payload) {
      let res = await context.dispatch("getAxios", {
        url: `cerPack/status/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          context.commit("deleteCerPack", res.data._id);
          ElMessage({
            message: `${context.state.cerPackTitle} o'chirildi`,
            cerPack: "warning",
          });
        } else {
          context.commit("saveCerPack", res.data);
          ElMessage({
            message: `${context.state.cerPackTitle} holati o'zgartirildi`,
            cerPack: "success",
          });
        }
      }
    },
    async editCerPack(context, payload) {
      return await context.dispatch("getAxios", {
        url: `cerPack/${payload}`,
      });
    },
    async saveCerPack(context, payload) {
      let res = await context.dispatch("putAxios", {
        url: `cerPack`,
        data: payload,
      });

      if (res.status == 200) {
        context.commit("saveCerPack", res.data);
        ElMessage({
          message: `${context.state.cerPackTitle} yangilandi`,
          cerPack: "success",
        });
      }
    },
    async addCerPack(context, payload) {
      let res = await context.dispatch("postAxios", {
        url: "cerPack",
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: `Yangi ${context.state.cerPackTitle.toLowerCase()} qo'shildi`,
          cerPack: "success",
        });
        context.commit("newCerPack", res.data);
      }
    },
    async showCers({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", {
        url: `certificate/show/${payload}`,
      });

      commit("cerPack", res.data);
    },
  },
};
