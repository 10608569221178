export const bossSettings = [
    {
        path:'testtype',
        icon:'tickets',
        name: 'testtype',
        title:'Test turlari',
        component:() => import(/* webpackChunkName: "testtype" */ '../views/settings/boss/TestType.vue'),
        meta: { role:['boss'] }
    },
]

export const bossMenu = [
    {
        path:'/teacher',
        icon:'avatar',
        name: 'teacher',
        title:'O`qituvchilar',
        component:() => import(/* webpackChunkName: "teacher" */ '../views/boss/TeacherList.vue'),
        meta: { role:['admin','rector','director'] }
    },
    {
        path:'/group',
        icon:'user',
        name: 'group',
        title:'Guruhlar',
        component:() => import(/* webpackChunkName: "group" */ '../views/boss/GroupList.vue'),
        meta: { role:['admin','rector','director'] }
    },
    {
        path:'/student',
        icon:'user-filled',
        name: 'student',
        title:'O`quvchilar',
        component:() => import(/* webpackChunkName: "student" */ '../views/boss/StudentList.vue'),
        meta: { role:['admin','rector','director'] }
    },
    {
        path:'/test',
        icon:'list',
        name: 'test',
        title:'Testlar',
        component:() => import(/* webpackChunkName: "test" */ '../views/boss/TestList.vue'),
        meta: { role:['admin','rector','director','teacher'] },
        children: [
            {
                path:'',
                name: 'testlist',
                title:'Testlar ro`yhati',
                component:() => import(/* webpackChunkName: "testlist" */ '../views/boss/test/AllTest.vue'),
                meta: { role:['admin','rector','director','teacher'] }
            },
            {
                path:'add',
                name: 'addtest',
                title:'Yangi test',
                component:() => import(/* webpackChunkName: "addtest" */ '../views/boss/test/AddTest.vue'),
                meta: { role:['admin','rector','director','teacher'] }
            },
        ]
    },
    {
        path:'/testpack',
        icon:'list',
        name: 'testpack',
        title:'To`plamlar',
        component:() => import(/* webpackChunkName: "testpack" */ '../views/boss/TestList.vue'),
        meta: { role:['admin','rector','director','teacher'] },
        children: [
            {
                path:'',
                name: 'testpacklist',
                title:'Test to`plamlar ro`yhati',
                component:() => import(/* webpackChunkName: "testpacklist" */ '../views/boss/testpack/AllTestpack.vue'),
                meta: { role:['admin','rector','director'] },
            },
            {
                path:'add',
                name: 'addtestpack',
                title:'Yangi test to`plam',
                component:() => import(/* webpackChunkName: "addtestpack" */ '../views/boss/testpack/AddTestpack.vue'),
                meta: { role:['admin','rector','director'] },
            },
        ]
    },
]

export const bossLinks = [
    ...bossMenu,
    
    {
        path:'/test/edit/:id',
        name: 'editTest',
        component:() => import(/* webpackChunkName: "editTest" */ '../views/boss/test/EditTest.vue'),
    },
    {
        path:'/testpack/edit/:id',
        name: 'editTestpack',
        component:() => import(/* webpackChunkName: "editTestpack" */ '../views/boss/testpack/EditTestpack.vue'),
    },
]