import { ElMessage } from 'element-plus'

export const group = {
    state: () => ({
        groups:[],
        countGroup:0
    }),
    getters:{
        countGroup(state){
            return state.countGroup
        },
        groups(state){
            return state.groups
        },
        activeGroups(state){
            return state.groups.filter(group => group.status == 0)
        },
        activeSortGroups(state){
            let groups = state.groups.filter(group => group.status == 0)
            return groups.sort()
        }
    },
    mutations:{
        countGroup(state,payload){
            state.countGroup = payload
        },
        groups(state,payload){
            state.groups = payload
        },
        newGroup(state,payload){
            state.groups = [payload,...state.groups]
            state.countGroup += 1
        },
        saveGroup(state,payload){
            let index = state.groups.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.groups[index] = payload
            }
        },
        deleteGroup(state,payload){
            state.groups = state.groups.filter(group => {
                if (group._id == payload) return false
                return group
            })
            state.countGroup -= 1
        }
    },
    actions:{
        async getGroups(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'group',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('groups',res.data)
            }
        },
        async getCountGroups(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'group/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countGroup',res.data.group)
            }
        },

        async addGroup(context,payload){
            let res = await context.dispatch('postAxios',{url:'group',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi guruh qo`shildi',
                    type: 'success',
                })
                context.commit('newGroup',res.data)
            }
        },

        async changeStatusGroup(context,payload){
            let res = await context.dispatch('getAxios',{url:`group/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveGroup',res.data)
                ElMessage({
                    message: 'Guruh holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editGroup(context,payload){
            return await context.dispatch('getAxios',{url:`group/edit/${payload}`})
        },
        async saveGroup(context,payload){
            let res = await context.dispatch('putAxios',{url:`group/save`,data: payload})
            if (res.status == 200){
                context.commit('saveGroup',res.data)
                ElMessage({
                    message: 'Guruh yangilandi',
                    type: 'success',
                })
            }
        },
        

        

        async removeGroup(context,payload){
            let res = await context.dispatch('deleteAxios',`group/delete/${payload}`)
            if (res.status == 200){
                context.commit('deleteGroup',payload)
                ElMessage({
                    message: 'Foydalanuvchi o`chirildi',
                    type: 'warning',
                })
            }
        },
        async removeGroupFile(context,payload){
            return await context.dispatch('postAxios',
                {url:`group/files/delete`,
                data: {resFile:payload}
            })
        },
    }
}