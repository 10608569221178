import { convertDate } from '@/store/modules/helpers';
import { ElMessage } from 'element-plus';

export const final = {
  state: () => ({
    finals: [],
    final: {
      finallist: [],
    },
    countFinal: 0,
    finalTitle: 'Imtixon',
  }),
  getters: {
    countFinal({ countFinal }) {
      return countFinal;
    },
    finals({ finals }) {
      return finals.map((final) => {
        return { ...final, date: convertDate(final.date) };
      });
    },
    final({ final }) {
      return {...final,finallist: final.finallist.map(f => {
        f.enter = convertDate(f.enter)
        return f
      })};
    },
    activeFinals({ finals }) {
      return finals.filter((final) => final.status == 0);
    },
  },
  mutations: {
    countFinal(state, payload) {
      state.countFinal = payload;
    },
    finals(state, payload) {
      state.finals = payload;
    },
    final(state, payload) {
      state.final = payload;
    },
    newFinal(state, payload) {
      state.finals = [payload, ...state.finals];
      state.countFinal += 1;
    },
    saveFinal(state, payload) {
      let index = state.finals.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.finals[index] = payload;
      }
    },
    deleteFinal(state, payload) {
      state.finals = state.finals.filter((final) => {
        if (final._id == payload) return false;
        return final;
      });
      state.countFinal -= 1;
    },
    updateFinallist(state, payload) {
      state.final.finallist = state.final.finallist.map((p) => {
        if (p._id == payload._id) return payload;
        return p;
      });
    },
  },
  actions: {
    async changeStatusFinallist({ dispatch, commit }, payload) {
      let res = await dispatch('getAxios', {
        url: `finallist/status/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        // console.log(res.data);
        commit('updateFinallist', res.data);
        ElMessage({
          message: `Olimpiadist holati o'zgartirildi`,
          type: 'success',
        });
      }
    },
    async saveFinallist({ dispatch, commit }, payload) {
      let res = await dispatch('putAxios', {
        url: `finallist/`,
        data: payload
      });
      if (res.status == 200) {
        commit('updateFinallist', res.data);
        ElMessage({
          message: `Olimpiadist holati o'zgartirildi`,
          type: 'success',
        });
      }
    },
    async getFinallist({ dispatch }, payload) {
      return await dispatch('getAxios', {
        url: `finallist/get/${payload}`,
      });
    },
    async getFinals({ dispatch, commit }, payload) {
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: 'final',
        search: payload,
      });
      if (res.status == 200) {
        // console.log(res.data);
        commit('loading', false);
        commit('finals', res.data.final);
        commit('countFinal', res.data.count);
      }
    },
    async getFinal({ dispatch, commit }, payload) {
      commit('loading', true);
      let res = await dispatch('getAxios', {
        url: `final/show/${payload._id}`,
        search: payload
      });
      if (res.status == 200) {
        // console.log(res.data)
        commit('loading', false);
        commit('final', res.data.final);
        commit('countFinal', res.data.count);
      }
    },
    async changeStatusFinal({ dispatch, commit, state }, payload) {
      let res = await dispatch('getAxios', {
        url: `final/status/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          commit('deleteFinal', res.data._id);
          ElMessage({
            message: `${state.finalTitle} o'chirildi`,
            type: 'warning',
          });
        } else {
          commit('saveFinal', res.data);
          ElMessage({
            message: `${state.finalTitle} holati o'zgartirildi`,
            type: 'success',
          });
        }
      }
    },
    async editFinal({ dispatch }, payload) {
      return await dispatch('getAxios', {
        url: `final/get/${payload}`,
      });
    },
    async saveFinal({ dispatch, commit, getters }, payload) {
      let res = await dispatch('putAxios', {
        url: `final`,
        data: payload,
      });

      if (res.status == 200) {
        commit('saveFinal', res.data);
        ElMessage({
          message: `${getters.finalTitle} yangilandi`,
          type: 'success',
        });
      }
    },
    async addFinal({ dispatch, state, commit }, payload) {
      commit('loading', true);
      ElMessage({
        message: `Yangi jadval yaratilish jarayonida`,
        type: 'success',
      })
      let res = await dispatch('postAxios', {
        url: 'final',
        data: payload,
      });
      if (res.status == 201) {
        commit('newFinal', res.data);
        commit('loading', false)
        ElMessage({
          message: `Yangi ${state.finalTitle.toLowerCase()} qo'shildi`,
          type: 'success',
        })
      }
    },
    excelFinal({ dispatch }, payload) {
      return dispatch('getAxios', {
        url: `final/excel/${payload._id}`,
        search: payload,
      });
    },
  },
};
