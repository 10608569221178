

export const statistic = {
    state: () => ({
        statistic:[],
        statResult:[]
    }),
    getters:{
        
        statistic(state){
            return state.statistic
        },
        statResult({statResult}){
            return statResult
        }
    },
    mutations:{
        statistic(state,payload){
            state.statistic = payload.map((item) => {
                item.pupils = item.statistic.length 
                item.allball = 0 
                item.average = 0
                if (item.statistic.length > 0 ){
                    item.statistic.forEach(pupil => {
                        if (pupil.results){
                            pupil.results.forEach(result => {
                                    if (result.balls)
                                    item.allball += parseFloat((result.balls/result.allballs*100).toFixed(2))
                            })
                            
                        }
                    })
                    item.average = item.allball/item.statistic.length
                }
                
                return item
            })
        },
        statResult(state,payload){
            state.statResult = payload.map(item => {
                
                item.result = item.balls > 0 ? (item.balls/item.allballs*100).toFixed(2) : 0
                return item
            })
        }
    },
    actions:{
        
        async getStatistics({dispatch,commit},payload){
            commit('loading',true)
            let res = await dispatch('getAxios',{url:'statistic',search:payload})
            if (res.status == 200){
                
                commit('statistic',res.data)
                commit('loading',false)
            }
        },

        async getOlympicStatistic({dispatch,commit}){
            commit('loading',true)
            let res = await dispatch('getAxios',{url:'statistic/result'})
            if (res.status == 200){
                
                commit('loading',false)
                commit('statResult',res.data)
            }
        }
        
        
    }
}