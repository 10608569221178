import { ElMessage } from 'element-plus'

export const city = {
    state: () => ({
        citys:[],
        city:{},
        countCity:0
    }),
    getters:{
        countCity(state){
            return state.countCity
        },
        citys(state){
            return state.citys
        },
        city(state){
            return state.city
        },
        activeCitys(state){
            return state.citys.filter(city => city.status == 0)
        }
    },
    mutations:{
        countCity(state,payload){
            state.countCity = payload
        },
        citys(state,payload){
            state.citys = payload
        },
        city(state,payload){
            state.city = payload
        },
        newCity(state,payload){
            state.citys = [...payload,...state.citys]
            state.countCity += payload.length
        },
        saveCity(state,payload){
            let index = state.citys.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.citys[index] = payload
            }
        },
        deleteCity(state,payload){
            state.citys = state.citys.filter(city => {
                if (city._id == payload) return false
                return city
            })
            state.countCity -= 1
        }
    },
    actions:{
        async getCitys(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'city',search:payload})
            if (res.status == 200){
                
                context.commit('loading',false)
                context.commit('citys',res.data)
            }
        },
        getAllCity({dispatch}){
            return dispatch('getAxios',{url:'city',search:{limit:0}})
        },
        async getCountCitys(context,payload){
            let res = await context.dispatch('getAxios',{url:'city/count',search:payload})
            if (res.status == 200){
                
                context.commit('countCity',res.data.count)
            }
        },
        async changeStatusCity(context,payload){
            let res = await context.dispatch('getAxios',{url:`city/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deleteCity',res.data._id)
                    ElMessage({
                    message: 'Viloyat o`chirildi',
                        type: 'warning',
                    })
                } else {
                    context.commit('saveCity',res.data)
                    ElMessage({
                    message: 'Viloyat holati o`zgartirildi',
                        type: 'success',
                    })
                }
            }
        },
        async editCity(context,payload){
            return await context.dispatch('getAxios',{url:`city/${payload}`})
        },
        async saveCity(context,payload){
            let res = await context.dispatch('putAxios',{url:`city`,data: payload})
            
            if (res.status == 200){
                context.commit('saveCity',res.data)
                ElMessage({
                    message: 'Viloyat yangilandi',
                    type: 'success',
                })
            }
        },
        async addCity(context,payload){
            let res = await context.dispatch('postAxios',{url:'city',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi viloyatlar qo`shildi',
                    type: 'success',
                })
                context.commit('newCity',res.data)
            }
        },
    }
}