import { ElMessage } from "element-plus";

import { addZero } from "@/store/modules/helpers";

export const testproccess = {
  state: () => ({
    testproccesss: [],
    testproccess: {
      type: "",
    },
    countTestproccess: 0,
    testproccessTitle: "Test jarayon",
    resultestproccess: [],
  }),
  getters: {
    resultestproccess({ resultestproccess }) {
      return resultestproccess;
    },
    testproccessTitle(state) {
      return state.testproccessTitle;
    },
    countTestproccess(state) {
      return state.countTestproccess;
    },
    testproccesss(state) {
      return state.testproccesss;
    },
    testproccess(state) {
      return state.testproccess;
    },
    activeTestproccesss(state) {
      return state.testproccesss.filter(
        (testproccess) => testproccess.status == 0
      );
    },
  },
  mutations: {
    countTestproccess(state, payload) {
      state.countTestproccess = payload;
    },
    testproccesss(state, payload) {
      state.testproccesss = payload.map((item) => {
        let now = new Date();
        let dateStart = new Date(item.start);
        let dateEnd = new Date(item.end);

        item.ball =
          item.results?.balls > 0
            ? (item.results.balls / item.results.allballs) * 100
            : 0;

        item.open =
          now.valueOf() >= dateStart.valueOf() &&
          now.valueOf() <= dateEnd.valueOf()
            ? true
            : false;
        item.late = now.valueOf() >= dateEnd.valueOf() ? true : false;
        item.earl = now.valueOf() <= dateStart.valueOf() ? true : false;

        item.start = `${addZero(dateStart.getHours())}:${addZero(
          dateStart.getMinutes()
        )} 
            ${addZero(dateStart.getDate())}/${addZero(
          dateStart.getMonth() + 1
        )}/${addZero(dateStart.getFullYear())}`;
        item.end = `${addZero(dateEnd.getHours())}:${addZero(
          dateEnd.getMinutes()
        )} ${addZero(dateEnd.getDate())}/${addZero(
          dateEnd.getMonth() + 1
        )}/${addZero(dateEnd.getFullYear())} `;
        item.testsCount = 0;
        if (item.testpack.settings) {
          item.testpack.settings.forEach((setting) => {
            if (setting.themes) {
              setting.themes.forEach((theme) => {
                item.testsCount += theme.gencount;
              });
            }
          });
        }

        return item;
      });
    },
    testproccess(state, payload) {
      state.testproccess = payload;
    },
    newTestproccess(state, payload) {
      let dateStart = new Date(payload.start);
      let dateEnd = new Date(payload.end);

      payload.start = `${addZero(dateStart.getHours())}:${addZero(
        dateStart.getMinutes()
      )} 
        ${addZero(dateStart.getDate())}/${addZero(
        dateStart.getMonth() + 1
      )}/${addZero(dateStart.getFullYear())}`;
      payload.end = `${addZero(dateEnd.getHours())}:${addZero(
        dateEnd.getMinutes()
      )} ${addZero(dateEnd.getDate())}/${addZero(
        dateEnd.getMonth() + 1
      )}/${addZero(dateEnd.getFullYear())} `;
      state.testproccesss = [payload, ...state.testproccesss];
      state.countTestproccess += 1;
    },
    saveTestproccess(state, payload) {
      let index = state.testproccesss.findIndex(
        (dep) => dep._id == payload._id
      );
      if (index !== -1) {
        let dateStart = new Date(payload.start);
        let dateEnd = new Date(payload.end);
        state.testproccesss[index] = payload;
        state.testproccesss[index].start = `${addZero(
          dateStart.getHours()
        )}:${addZero(dateStart.getMinutes())} 
        ${addZero(dateStart.getDate())}/${addZero(
          dateStart.getMonth() + 1
        )}/${addZero(dateStart.getFullYear())}`;
        state.testproccesss[index].end = `${addZero(
          dateEnd.getHours()
        )}:${addZero(dateEnd.getMinutes())} ${addZero(
          dateEnd.getDate()
        )}/${addZero(dateEnd.getMonth() + 1)}/${addZero(
          dateEnd.getFullYear()
        )} `;
      }
    },
    deleteTestproccess(state, payload) {
      state.testproccesss = state.testproccesss.filter((testproccess) => {
        if (testproccess._id == payload) return false;
        return testproccess;
      });
      state.countTestproccess -= 1;
    },
    removeResultTestproccess(state, payload) {
      state.resultestproccess = state.resultestproccess.filter((result) => {
        if (result._id == payload) return false;
        return result;
      });
      state.countTestproccess -= 1;
    },
    resultestproccess(state, payload) {
      state.resultestproccess = payload.map((person) => {
        // person.allballs = 0
        if (person.result) {
          person.allballs = person.result.allballs;
          person.balls = person.result.balls || 0;
        }

        return person;
      });
    },
  },
  actions: {
    async getTestproccesss(context, payload) {
      context.commit("loading", true);
      let res = await context.dispatch("getAxios", {
        url: "testproccess",
        search: payload,
      });

      context.commit("loading", false);
      context.commit("testproccesss", res.data);
    },
    async getCountTestproccesss(context, payload) {
      let res = await context.dispatch("getAxios", {
        url: "testproccess/count",
        search: payload,
      });
      if (res.status == 200) {
        context.commit("countTestproccess", res.data.count);
      }
    },
    async changeStatusTestproccess(context, payload) {
      let res = await context.dispatch("getAxios", {
        url: `testproccess/change/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          context.commit("deleteTestproccess", res.data._id);
          ElMessage({
            message: `${context.state.testproccessTitle} o'chirildi`,
            testproccess: "warning",
          });
        } else {
          context.commit("saveTestproccess", res.data);
          ElMessage({
            message: `${context.state.testproccessTitle} holati o'zgartirildi`,
            testproccess: "success",
          });
        }
      }
    },
    async editTestproccess(context, payload) {
      return await context.dispatch("getAxios", {
        url: `testproccess/${payload}`,
      });
    },
    async saveTestproccess(context, payload) {
      let res = await context.dispatch("putAxios", {
        url: `testproccess`,
        data: payload,
      });

      if (res.status == 200) {
        context.commit("saveTestproccess", res.data);
        ElMessage({
          message: `${context.state.testproccessTitle} yangilandi`,
          testproccess: "success",
        });
      }
    },
    async deleteFileTestproccess({ dispatch }, payload) {
      return dispatch("postAxios", {
        url: "testproccess/files/delete",
        data: payload,
      });
    },
    async addTestproccess(context, payload) {
      let res = await context.dispatch("postAxios", {
        url: "testproccess",
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: `Yangi ${context.state.testproccessTitle.toLowerCase()} qo'shildi`,
          testproccess: "success",
        });
        context.commit("newTestproccess", res.data);
      }
    },

    async getTestproccesssOlympic({ dispatch, commit }) {
      commit("loading", true);
      let res = await dispatch("getAxios", { url: "testproccess/alympik" });

      commit("testproccesss", res.data);
      commit("loading", false);
    },
    excelTestproccess({ dispatch }, payload) {
      return dispatch("getAxios", {
        url: `testproccess/excel/${payload.id}`,
        search: payload.search,
      });
    },
    async removeResultTestproccess({ dispatch, commit }, payload) {
      let res = await dispatch("deleteAxios", `result/delete/${payload}`);
      if (res.status == 200) {
        commit("removeResultTestproccess", payload);
        ElMessage({
          message: `Natija o'chirildi`,
          testproccess: "warning",
        });
      }
    },

    async getResultTestproccess({ dispatch, commit }, payload) {
      commit("loading", true);
      let res = await dispatch("getAxios", {
        url: `testproccess/onecheck/${payload.id}`,
        search: payload.search,
      });
      if (res.status == 200) {
        // console.log(res.data.result);
        commit("resultestproccess", res.data.result);
        commit("testproccess", res.data.testprocces);
        commit("countTestproccess", res.data.count);

        commit("loading", false);
      }
    },
  },
};
