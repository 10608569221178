import { ElMessage } from 'element-plus';

export const litsey = {
  state: () => ({
    litseys: [],
    litsey: {},
    countLitsey: 0,
  }),
  getters: {
    countLitsey(state) {
      return state.countLitsey;
    },
    litseys(state) {
      return state.litseys;
    },
    litsey(state) {
      return state.litsey;
    },
    activeLitseys(state) {
      return state.litseys.filter((litsey) => litsey.status == 0);
    },
  },
  mutations: {
    countLitsey(state, payload) {
      state.countLitsey = payload;
    },
    litseys(state, payload) {
      state.litseys = payload;
    },
    litsey(state, payload) {
      state.litsey = payload;
    },
    newLitsey(state, payload) {
      state.litseys = [...payload, ...state.litseys];
      state.countLitsey += payload.length;
    },
    saveLitsey(state, payload) {
      let index = state.litseys.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.litseys[index] = payload;
      }
    },
    deleteLitsey(state, payload) {
      state.litseys = state.litseys.filter((litsey) => {
        if (litsey._id == payload) return false;
        return litsey;
      });
      state.countLitsey -= 1;
    },
  },
  actions: {
    async getLitseys(context, payload) {
      context.commit('loading', true);
      let res = await context.dispatch('getAxios', {
        url: 'litsey',
        search: payload,
      });
      if (res.status == 200) {
        context.commit('loading', false);
        context.commit('litseys', res.data);
      }
    },
    async getCountLitseys(context, payload) {
      let res = await context.dispatch('getAxios', {
        url: 'litsey/count',
        search: payload,
      });
      if (res.status == 200) {
        context.commit('countLitsey', res.data.count);
      }
    },
    async changeStatusLitsey(context, payload) {
      let res = await context.dispatch('getAxios', {
        url: `litsey/status/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          context.commit('deleteLitsey', res.data._id);
          ElMessage({
            message: 'Litsey o`chirildi',
            type: 'warning',
          });
        } else {
          context.commit('saveLitsey', res.data);
          ElMessage({
            message: 'Litsey holati o`zgartirildi',
            type: 'success',
          });
        }
      }
    },
    async editLitsey(context, payload) {
      return await context.dispatch('getAxios', { url: `litsey/${payload}` });
    },
    async saveLitsey(context, payload) {
      let res = await context.dispatch('putAxios', {
        url: `litsey`,
        data: payload,
      });

      if (res.status == 200) {
        context.commit('saveLitsey', res.data);
        ElMessage({
          message: 'Litsey yangilandi',
          type: 'success',
        });
      }
    },
    async addLitsey(context, payload) {
      let res = await context.dispatch('postAxios', {
        url: 'litsey',
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: 'Yangi litsey qo`shildi',
          type: 'success',
        });
        context.commit('newLitsey', res.data);
      }
    },
    addLitseys({ dispatch }, payload) {
      return dispatch('postAxios', {
        url: 'litsey',
        data: payload,
      });
    },
  },
};
