import { ElMessage } from 'element-plus'

export const language = {
    state: () => ({
        languages:[],
        language:{},
        countLanguage:0
    }),
    getters:{
        countLanguage(state){
            return state.countLanguage
        },
        languages(state){
            return state.languages
        },
        language(state){
            return state.language
        },
        activeLanguages(state){
            return state.languages.filter(language => language.status == 0)
        },
        studyLanguages(state){
            return state.languages.filter(language => language.study == 1)
        }
    },
    mutations:{
        countLanguage(state,payload){
            state.countLanguage = payload
        },
        languages(state,payload){
            state.languages = payload
        },
        language(state,payload){
            state.language = payload
        },
        newLanguage(state,payload){
            state.languages = [payload,...state.languages]
            state.countLanguage += 1
        },
        saveLanguage(state,payload){
            let index = state.languages.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.languages[index] = payload
            }
        },
        deleteLanguage(state,payload){
            state.languages = state.languages.filter(language => {
                if (language._id == payload) return false
                return language
            })
            state.countLanguage -= 1
        }
    },
    actions:{
        
        async removeLanguage(context,payload){
            let res = await context.dispatch('deleteAxios',`language/delete/${payload}`)
            if (res.status == 200){
                context.commit('deleteLanguage',payload)
                ElMessage({
                    message: 'Til o`chirildi',
                    type: 'warning',
                })
            }
        },
        async getLanguages(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'language',search:payload})
            
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('languages',res.data)
            }
        },
        async getCountLanguages(context,payload){
            let res = await context.dispatch('getAxios',{url:'language/count',search:payload})
            if (res.status == 200){
                context.commit('countLanguage',res.data.count)
            }
        },
        async changeStatusLanguage(context,payload){
            let res = await context.dispatch('getAxios',{url:`language/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deleteLanguage',res.data._id)
                    ElMessage({
                    message: 'Ta`lim turi o`chirildi',
                        type: 'warning',
                    })
                } else {
                    context.commit('saveLanguage',res.data)
                    ElMessage({
                    message: 'Ta`lim turi holati o`zgartirildi',
                        type: 'success',
                    })

                }
            }
        },
        async editLanguage(context,payload){
            return await context.dispatch('getAxios',{url:`language/${payload}`})
        },
        async saveLanguage(context,payload){
            let res = await context.dispatch('putAxios',{url:`language/`,data: payload})
            
            if (res.status == 200){
                context.commit('saveLanguage',res.data)
                ElMessage({
                    message: 'Til yangilandi',
                    type: 'success',
                })
            }
        },
        async addLanguage(context,payload){
            let res = await context.dispatch('postAxios',{url:'language',data: payload})
            
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi til qo`shildi',
                    type: 'success',
                })
                context.commit('newLanguage',res.data)
            }
        },

    }
}