import { ElMessage } from 'element-plus'
import router from '@/router'
export const test = {
    state: () => ({
        tests:[],
        countTest:0
    }),
    getters:{
        countTest(state){
            return state.countTest
        },
        tests(state){
            return state.tests
        },
        activeTests(state){
            return state.tests.filter(test => test.status == 0)
        },
        activeSortTests(state){
            let tests = state.tests.filter(test => test.status == 0)
            return tests.sort()
        }
    },
    mutations:{
        countTest(state,payload){
            state.countTest = payload
        },
        tests(state,payload){
            state.tests = payload
        },
        newTest(state,payload){
            state.tests = [payload,...state.tests]
            state.countTest += 1
        },
        saveTest(state,payload){
            let index = state.tests.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.tests[index] = payload
            }
        },
        deleteTest(state,payload){
            state.tests = state.tests.filter(test => {
                if (test._id == payload) return false
                return test
            })
            state.countTest -= 1
        }
    },
    actions:{
        async getTests(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'test',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('tests',res.data)
            }
        },
        async getTestsShow({dispatch},payload){
            return dispatch('getAxios',{url:'test',search:payload})
        },
        async getTestsCount({dispatch,commit},payload){
            commit ('loading',true)
            let res = await dispatch('getAxios',{url:'test/count',search:payload})
            if (res.status === 200){
                commit('loading',false)
                commit('countTest',res.data.test)
            }
        },

        async addTest(context,payload){
            let res = await context.dispatch('postAxios',{url:'test',data: payload})
            if (res.status == 201) {
                router.push('/test')
                ElMessage({
                    message: 'Yangi testlar qo`shildi',
                    type: 'success',
                })
                context.commit('newTest',res.data)
            }
        },

        async changeStatusTest(context,payload){
            let res = await context.dispatch('getAxios',{url:`test/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveTest',res.data)
                ElMessage({
                    message: 'Test holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editTest(context,payload){
            return await context.dispatch('getAxios',{url:`test/edit/${payload}`})
        },
        async saveTest(context,payload){
            let res = await context.dispatch('putAxios',{url:`test`,data: payload})
            if (res.status == 200){
                context.commit('saveTest',res.data)
                router.push('/test')
                ElMessage({
                    message: 'Test yangilandi',
                    type: 'success',
                })
            }
        },
        

        

        async removeTest(context,payload){
            let res = await context.dispatch('deleteAxios',`test/delete/${payload}`)
            if (res.status == 200){
                context.commit('deleteTest',payload)
                ElMessage({
                    message: 'Test o`chirildi',
                    type: 'warning',
                })
            }
        },
        async removeTestFile(context,payload){
            return await context.dispatch('postAxios',
                {url:`test/files/delete`,
                data: {resFile:payload}
            })
        },
    }
}