import { ElMessage } from 'element-plus'

export const request = {
    state: () => ({
        requests:[],
        request:{},
        countRequest:0,
        requestTitle:'Arizalar'
    }),
    getters:{
        countRequest(state){
            return state.countRequest
        },
        requests(state){
            return state.requests
        },
        request(state){
            return state.request
        },
        activeRequests(state){
            return state.requests.filter(request => request.status == 0)
        }
    },
    mutations:{
        countRequest(state,payload){
            state.countRequest = payload
        },
        requests(state,payload){
            state.requests = payload
        },
        request(state,payload){
            state.request = payload
        },
        newRequest(state,payload){
            state.requests = [payload,...state.requests]
            state.countRequest += 1
        },
        saveRequest(state,payload){
            let index = state.requests.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.requests[index] = payload
            }
        },
        deleteRequest(state,payload){
            state.requests = state.requests.filter(request => {
                if (request._id == payload) return false
                return request
            })
            state.countRequest -= 1
        }
    },
    actions:{
        async getRequests(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'student/my',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('requests',res.data)
            }
        },
        async getCountRequests(context,payload){
            let res = await context.dispatch('getAxios',{url:'request/count',search:payload})
            if (res.status == 200){
                context.commit('countRequest',res.data.count)
            }
        },
        async changeStatusRequest(context,payload){
            let res = await context.dispatch('getAxios',{url:`request/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deleteRequest',res.data._id)
                    ElMessage({
                    message: `${context.getters.requestTitle} o'chirildi`,
                        type: 'warning',
                    })
                } else {
                    context.commit('saveRequest',res.data)
                    ElMessage({
                    message: `${context.getters.requestTitle} holati o'zgartirildi`,
                        type: 'success',
                    })

                }
            }
        },
        async editRequest(context,payload){
            return await context.dispatch('getAxios',{url:`request/${payload}`})
        },
        async saveRequest(context,payload){
            let res = await context.dispatch('putAxios',{url:`request`,data: payload})
            
            if (res.status == 200){
                context.commit('saveRequest',res.data.request)
                ElMessage({
                    message: `${context.getters.requestTitle} yangilandi`,
                    type: 'success',
                })
            }
        },
        async addRequest(context,payload){
            let res = await context.dispatch('postAxios',{url:'request',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: `Yangi ${context.state.requestTitle.toLowerCase()} qo'shildi`,
                    type: 'success',
                })
                context.commit('newRequest',res.data)
            }
        },
    }
}