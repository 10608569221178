import { ElMessage } from 'element-plus'

export const place = {
    state: () => ({
        places:[],
        place:{},
        countPlace:0
    }),
    getters:{
        countPlace(state){
            return state.countPlace
        },
        places(state){
            return state.places
        },
        place(state){
            return state.place
        },
        activePlaces(state){
            return state.places.filter(place => place.status == 0)
        }
    },
    mutations:{
        countPlace(state,payload){
            state.countPlace = payload
        },
        places(state,payload){
            state.places = payload
        },
        place(state,payload){
            state.place = payload
        },
        newPlace(state,payload){
            state.places = [payload,...state.places]
            state.countPlace += 1
        },
        savePlace(state,payload){
            let index = state.places.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.places[index] = payload
            }
        },
        deletePlace(state,payload){
            state.places = state.places.filter(place => {
                if (place._id == payload) return false
                return place
            })
            state.countPlace -= 1
        }
    },
    actions:{
        async getPlaces(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'place',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('places',res.data)
            }
        },
        async getCountPlaces(context,payload){
            let res = await context.dispatch('getAxios',{url:'place/count',search:payload})
            if (res.status == 200){
                context.commit('countPlace',res.data.count)
            }
        },
        async changeStatusPlace(context,payload){
            let res = await context.dispatch('getAxios',{url:`place/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deletePlace',res.data._id)
                    ElMessage({
                    message: 'O`quv maskan o`chirildi',
                        type: 'warning',
                    })
                } else {
                    context.commit('savePlace',res.data)
                    ElMessage({
                    message: 'O`quv maskan holati o`zgartirildi',
                        type: 'success',
                    })

                }
            }
        },
        async editPlace(context,payload){
            return await context.dispatch('getAxios',{url:`place/${payload}`})
        },
        async savePlace(context,payload){
            let res = await context.dispatch('putAxios',{url:`place`,data: payload})
            
            if (res.status == 200){
                context.commit('savePlace',res.data)
                ElMessage({
                    message: 'O`quv maskan yangilandi',
                    type: 'success',
                })
            }
        },
        async addPlace(context,payload){
            let res = await context.dispatch('postAxios',{url:'place',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi o`quv maskan qo`shildi',
                    type: 'success',
                })
                context.commit('newPlace',res.data)
            }
        },
    }
}