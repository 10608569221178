export const adminSettings = [
    {
        path:'subject',
        icon:'reading',
        name: 'subject',
        title:'Fanlar',
        component:() => import(/* webpackChunkName: "subject" */ '../views/boss/SubjectList.vue'),
        meta: { role:['admin','rector','director'] },
    },
    
    {
        path:'subjectcategory',
        icon:'collection',
        name: 'subjectcategory',
        title:'Fan turlari',
        component:() => import(/* webpackChunkName: "subjectcategory" */ '../views/settings/boss/SubjectCategory.vue'),
        meta: { role:['admin','rector','director'] }
    },
    // {
    //     path:'place',
    //     icon:'office-building',
    //     name: 'place',
    //     title:'O`quv maskanlar',
    //     component:() => import(/* webpackChunkName: "place" */ '../views/settings/PlaceList.vue'),
    //     meta: { role:['admin','rector','director'] }
    // },
    // {
    //     path:'citizen',
    //     icon:'stamp',
    //     name: 'citizen',
    //     title:'Fuqarolik',
    //     component:() => import(/* webpackChunkName: "citizen" */ '../views/settings/CitizenList.vue'),
    //     meta: { role:['admin','rector','director'] }
    // },
    // {
    //     path:'region',
    //     icon:'location',
    //     name: 'region',
    //     title:'Viloyatlar',
    //     component:() => import(/* webpackChunkName: "region" */ '../views/settings/RegionList.vue'),
    //     meta: { role:['admin','rector','director'] }
    // },
    // {
    //     path:'city',
    //     icon:'location-information',
    //     name: 'city',
    //     title:'Shahar/Tuman',
    //     component:() => import(/* webpackChunkName: "city" */ '../views/settings/CityList.vue'),
    //     meta: { role:['admin','rector','director'] }
    // },
    // {
    //     path:'school',
    //     icon:'school',
    //     name: 'school',
    //     title:'Maktab',
    //     component:() => import(/* webpackChunkName: "school" */ '../views/settings/SchoolList.vue'),
    //     meta: { role:['admin','rector','director'] }
    // },
    // {
    //     path:'litsey',
    //     icon:'school',
    //     name: 'litsey',
    //     title:'Litsey',
    //     component:() => import(/* webpackChunkName: "litsey" */ '../views/settings/LitseyList.vue'),
    //     meta: { role:['admin','rector','director'] }
    // },
    // {
    //     path:'nationality',
    //     icon:'flag',
    //     name: 'nationality',
    //     title:'Millatlar',
    //     component:() => import(/* webpackChunkName: "nationality" */ '../views/settings/NationalityPage.vue'),
    //     meta: { role:['admin'] }
    // },
    // {
    //     path:'room',
    //     icon:'school',
    //     name: 'room',
    //     title:'Xonalar',
    //     component:() => import(/* webpackChunkName: "room" */ '../views/settings/RoomList.vue'),
    //     meta: { role:['admin'] }
    // },
    // {
    //     path:'social',
    //     icon:'share',
    //     name: 'social',
    //     title:'Ijtimoiy tarmoqlar',
    //     component:() => import(/* webpackChunkName: "social" */ '../views/settings/SocialPage.vue'),
    //     meta: { role:['admin'] }
    // },
    // {
    //     path:'usertype',
    //     icon:'user',
        
    //     name: 'usertype',
    //     title:'Foydalanuvchi turi',
    //     component:() => import(/* webpackChunkName: "usertype" */ '../views/settings/UserTypePage.vue'),
    //     meta: { role:['admin'] }
    // },
    {
        path:'language',
        icon:'mic',
        name: 'language',
        title:'Tillar',
        component:() => import(/* webpackChunkName: "language" */ '../views/settings/LanguageList.vue'),
        meta: { role:['admin'] }
    },
    
]

export const adminMenu = [
  {
    path: '/users',
    icon: 'user',
    name: 'users',
    title: 'Foydalanuvchilar',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/admin/UserList.vue'),
    meta: { role: ['admin'] },
  },
  {
    path: '/test',
    icon: 'list',
    name: 'test',
    title: 'Test savollari',
    component: () =>
      import(/* webpackChunkName: "test" */ '../views/boss/TestList.vue'),
    meta: { role: ['admin', 'rector', 'director', 'teacher'] },
    children: [
      {
        path: '',
        name: 'testlist',
        title: 'Testlar ro`yhati',
        component: () =>
          import(
            /* webpackChunkName: "testlist" */ '../views/boss/test/AllTest.vue'
          ),
        meta: { role: ['admin', 'rector', 'director', 'teacher'] },
      },
      {
        path: 'add',
        name: 'addtest',
        title: 'Yangi test',
        component: () =>
          import(
            /* webpackChunkName: "addtest" */ '../views/boss/test/AddTest.vue'
          ),
        meta: { role: ['admin', 'rector', 'director', 'teacher'] },
      },
    ],
  },
  {
    path: '/testpack',
    icon: 'copy-document',
    name: 'testpack',
    title: 'To`plamlar',
    component: () =>
      import(/* webpackChunkName: "testpack" */ '../views/boss/TestList.vue'),
    meta: { role: ['admin', 'rector', 'director'] },
    children: [
      {
        path: '',
        name: 'testpacklist',
        title: 'Test to`plamlar ro`yhati',
        component: () =>
          import(
            /* webpackChunkName: "testpacklist" */ '../views/boss/testpack/AllTestpack.vue'
          ),
        meta: { role: ['admin', 'rector', 'director'] },
      },
      {
        path: 'add',
        name: 'addtestpack',
        title: 'Yangi test to`plam',
        component: () =>
          import(
            /* webpackChunkName: "addtestpack" */ '../views/boss/testpack/AddTestpack.vue'
          ),
        meta: { role: ['admin', 'rector', 'director'] },
      },
    ],
  },
  {
    path: '/testproccess',
    icon: 'edit-pen',
    name: 'testproccess',
    title: 'Test jarayon',
    component: () =>
      import(
        /* webpackChunkName: "testproccess" */ '../views/boss/TestProccess.vue'
      ),
    meta: { role: ['admin', 'rector', 'director'] },
  },
  {
    path: '/message',
    icon: 'message',
    name: 'message',
    title: 'Xabar',
    component: () =>
      import(/* webpackChunkName: "message" */ '../views/boss/MessageList.vue'),
    meta: { role: ['admin', 'rector', 'director'] },
    children: [
      {
        path: '',
        name: 'messagelist',
        title: 'Xabarlar ro`yhati',
        component: () =>
          import(
            /* webpackChunkName: "messagelist" */ '../views/boss/message/AllMessage.vue'
          ),
        meta: { role: ['admin', 'rector', 'director'] },
      },
      {
        path: 'add',
        name: 'addmessage',
        title: 'Yangi xabar',
        component: () =>
          import(
            /* webpackChunkName: "addmessage" */ '../views/boss/message/AddMessage.vue'
          ),
        meta: { role: ['admin', 'rector', 'director'] },
      },
      {
        path: 'show/:id',
        name: 'showmessage',
        title: 'Xabarga javoblar',
        component: () =>
          import(
            /* webpackChunkName: "showmessage" */ '../views/boss/message/ShowMessage.vue'
          ),
        meta: { role: ['admin', 'rector', 'director'] },
      },
    ],
  },
  {
    path: '/cerpack',
    icon: 'folder',
    name: 'cerpack',
    title: 'Sertifikatlar',
    component: () =>
      import(
        /* webpackChunkName: "cerpack" */ '../views/admin/CerpackList.vue'
      ),
    meta: { role: ['admin', 'rector', 'director'] },
  },
  {
    path: '/final',
    icon: 'calendar',
    name: 'final',
    title: 'Imtihon',
    component: () =>
      import(
        /* webpackChunkName: "final" */ '../views/boss/FinalList.vue'
      ),
    meta: { role: ['admin', 'rector', 'director'] },    
  },
  
];

export const adminLinks = [
  ...adminMenu,
  {
    path: '/cerpack/show/:id',
    name: 'showcert',
    title: 'Xabarga javoblar',
    component: () =>
      import(
        /* webpackChunkName: "showcert" */ '../views/admin/ShowCerpack.vue'
      ),
    meta: { role: ['admin', 'rector', 'director'] },
  },
  {
    path: '/final/:id',
    name: 'finallist',
    title: 'Imtixon',
    component: () =>
      import(/* webpackChunkName: "finallist" */ '../views/boss/FinallistList.vue'),
    meta: { role: ['admin', 'rector', 'director'] },
  },
  {
    path: '/settings/subject/:id',
    name: 'subjectView',
    component: () =>
      import(
        /* webpackChunkName: "subjectView" */ '../views/boss/SubjectView.vue'
      ),
    meta: { role: ['admin', 'rector', 'director', 'teacher'] },
  },
  {
    path: '/testproccess/:id',
    name: 'testproccessView',
    component: () =>
      import(
        /* webpackChunkName: "testproccessView" */ '../views/boss/testproccess/ShowTestProccess.vue'
      ),
    meta: { role: ['admin', 'rector', 'director'] },
  },
];