import { ElMessage } from 'element-plus'

export const subjectcategory = {
    state: () => ({
        subjectcategorys:[],
        subjectcategory:{},
        countSubjectcategory:0
    }),
    getters:{
        countSubjectcategory(state){
            return state.countSubjectcategory
        },
        subjectcategorys(state){
            return state.subjectcategorys.filter(subjectcategory => subjectcategory.status !==2)
        },
        subjectcategory(state){
            return state.subjectcategory
        },
        activeSubjectcategorys(state){
            return state.subjectcategorys.filter(subjectcategory => subjectcategory.status == 0)
        }
    },
    mutations:{
        countSubjectcategory(state,payload){
            state.countSubjectcategory = payload
        },
        subjectcategorys(state,payload){
            state.subjectcategorys = payload
        },
        subjectcategory(state,payload){
            state.subjectcategory = payload
        },
        newSubjectcategory(state,payload){
            state.subjectcategorys = [payload,...state.subjectcategorys]
            state.countSubjectcategory += 1
        },
        saveSubjectcategory(state,payload){
            let index = state.subjectcategorys.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.subjectcategorys[index] = payload
            }
        },
        deleteSubjectcategory(state,payload){
            state.subjectcategorys = state.subjectcategorys.filter(subjectcategory => {
                if (subjectcategory._id == payload) return false
                return subjectcategory
            })
            state.countSubjectcategory -= 1
        }
    },
    actions:{
        
        async getSubjectcategorys(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'subjectcategory',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('subjectcategorys',res.data)
            }
        },
        async getCountSubjectcategorys(context,payload){
            let res = await context.dispatch('getAxios',{url:'subjectcategory/count',search:payload})
            if (res.status == 200){
                context.commit('countSubjectcategory',res.data.subjectcategory)
            }
        },
        async changeStatusSubjectcategory(context,payload){
            let res = await context.dispatch('getAxios',{url:`subjectcategory/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveSubjectcategory',res.data)
                ElMessage({
                    message: payload == 2 ? 'Fan turi o`chirildi' :'Fan turi holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editSubjectcategory(context,payload){
            return await context.dispatch('getAxios',{url:`subjectcategory/edit/${payload}`})
        },
        async saveSubjectcategory(context,payload){
            let res = await context.dispatch('putAxios',{url:`subjectcategory/save`,data: payload})
            if (res.status == 200){
                context.commit('saveSubjectcategory',res.data)
                ElMessage({
                    message: 'Fan turi yangilandi',
                    type: 'success',
                })
            }
        },
        async addSubjectcategory(context,payload){
            let res = await context.dispatch('postAxios',{url:'subjectcategory',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi fan turi qo`shildi',
                    type: 'success',
                })
                context.commit('newSubjectcategory',res.data)
            }
        },
        
    }
}