import { ElMessage } from 'element-plus'

export const teacher = {
    state: () => ({
        teachers:[],
        countTeacher:0
    }),
    getters:{
        countTeacher(state){
            return state.countTeacher
        },
        teachers(state){
            return state.teachers
        },
        activeTeachers(state){
            return state.teachers.filter(teacher => teacher.status == 0)
        },
        activeSortTeachers(state){
            let teachers = state.teachers.filter(teacher => teacher.status == 0)
            return teachers.sort()
        }
    },
    mutations:{
        countTeacher(state,payload){
            state.countTeacher = payload
        },
        teachers(state,payload){
            state.teachers = payload
        },
        newTeacher(state,payload){
            state.teachers = [payload,...state.teachers]
            state.countTeacher += 1
        },
        saveTeacher(state,payload){
            let index = state.teachers.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.teachers[index] = payload
            }
        },
        deleteTeacher(state,payload){
            state.teachers = state.teachers.filter(teacher => {
                if (teacher._id == payload) return false
                return teacher
            })
            state.countTeacher -= 1
        }
    },
    actions:{
        async getTeachers(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'teacher',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('teachers',res.data)
            }
        },

        async addTeacher(context,payload){
            let res = await context.dispatch('postAxios',{url:'teacher',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi foydalanuvchi qo`shildi',
                    type: 'success',
                })
                context.commit('newTeacher',res.data)
            }
        },

        async changeStatusTeacher(context,payload){
            let res = await context.dispatch('getAxios',{url:`teacher/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveTeacher',res.data)
                ElMessage({
                    message: 'Foydalanuvchi holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editTeacher(context,payload){
            return await context.dispatch('getAxios',{url:`teacher/edit/${payload}`})
        },
        async saveTeacher(context,payload){
            let res = await context.dispatch('putAxios',{url:`teacher/save`,data: payload})
            if (res.status == 200){
                context.commit('saveTeacher',res.data)
                ElMessage({
                    message: 'Foydalanuvchi yangilandi',
                    type: 'success',
                })
            }
        },
        

        

        async removeTeacher(context,payload){
            let res = await context.dispatch('deleteAxios',`teacher/delete/${payload}`)
            if (res.status == 200){
                context.commit('deleteTeacher',payload)
                ElMessage({
                    message: 'Foydalanuvchi o`chirildi',
                    type: 'warning',
                })
            }
        },
        async removeTeacherFile(context,payload){
            return await context.dispatch('postAxios',
                {url:`teacher/files/delete`,
                data: {resFile:payload}
            })
        },
    }
}