import { ElMessage } from 'element-plus'

export const social = {
    state: () => ({
        socials:[],
        social:{},
        countSocial:0
    }),
    getters:{
        countSocial(state){
            return state.countSocial
        },
        socials(state){
            return state.socials
        },
        social(state){
            return state.social
        },
        activeSocials(state){
            return state.socials.filter(social => social.status == 0)
        }
    },
    mutations:{
        countSocial(state,payload){
            state.countSocial = payload
        },
        socials(state,payload){
            state.socials = payload
        },
        social(state,payload){
            state.social = payload
        },
        newSocial(state,payload){
            state.socials = [payload,...state.socials]
            state.countSocial += 1
        },
        saveSocial(state,payload){
            let index = state.socials.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.socials[index] = payload
            }
        },
        deleteSocial(state,payload){
            state.socials = state.socials.filter(social => {
                if (social._id == payload) return false
                return social
            })
            state.countSocial -= 1
        }
    },
    actions:{
        
        async removeSocial(context,payload){
            let res = await context.dispatch('deleteAxios',`social/delete/${payload}`)
            if (res.status == 200){
                context.commit('deleteSocial',payload)
                ElMessage({
                    message: 'Ijtimoy tarmoq o`chirildi',
                    type: 'warning',
                })
            }
        },
        async getSocials(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'social',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('socials',res.data)
            }
        },
        async getCountSocials(context,payload){
            let res = await context.dispatch('getAxios',{url:'social/count',search:payload})
            if (res.status == 200){
                context.commit('countSocial',res.data.social)
            }
        },
        async changeStatusSocial(context,payload){
            let res = await context.dispatch('getAxios',{url:`social/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveSocial',res.data)
                ElMessage({
                    message: 'Ijtimoy tarmoq holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editSocial(context,payload){
            return await context.dispatch('getAxios',{url:`social/edit/${payload}`})
        },
        async saveSocial(context,payload){
            let res = await context.dispatch('putAxios',{url:`social/save`,data: payload})
            
            if (res.status == 201){
                context.commit('saveSocial',res.data)
                ElMessage({
                    message: 'Ijtimoy tarmoq yangilandi',
                    type: 'success',
                })
            }
        },
        async addSocial(context,payload){
            let res = await context.dispatch('postAxios',{url:'social',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi ijtimoy tarmoq qo`shildi',
                    type: 'success',
                })
                context.commit('newSocial',res.data)
            }
        },
        async removeSocialFile(context,payload){
            return await context.dispatch('postAxios',
                {url:`social/files/delete`,
                data: {resFile:payload}
            })
        },
    }
}