import { ElMessage } from 'element-plus'

export const student = {
    state: () => ({
        students:[],
        countStudent:0
    }),
    getters:{
        countStudent(state){
            return state.countStudent
        },
        students(state){
            return state.students.filter(student => student.status != 2)
        },
        activeStudents(state){
            return state.students.filter(student => student.status == 0)
        },
        activeSortStudents(state){
            let students = state.students.filter(student => student.status == 0)
            return students.sort()
        }
    },
    mutations:{
        countStudent(state,payload){
            state.countStudent = payload
        },
        students(state,payload){
            state.students = payload
        },
        newStudent(state,payload){
            state.students = [payload,...state.students]
            state.countStudent += 1
        },
        saveStudent(state,payload){
            let index = state.students.findIndex(dep => dep._id == payload._id)
            if (index !==-1){                state.students[index] = payload
            }
        },
        deleteStudent(state,payload){
            state.students = state.students.filter(student => {
                if (student._id == payload) return false
                return student
            })
            state.countStudent -= 1
        }
    },
    actions:{
        async getStudents(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'student',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('students',res.data)
            }
        },

        async addStudent(context,payload){
            let res = await context.dispatch('postAxios',{url:'student',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi foydalanuvchi qo`shildi',
                    type: 'success',
                })
                context.commit('newStudent',res.data)
            }
        },

        async changeStatusStudent(context,payload){
            let res = await context.dispatch('getAxios',{url:`student/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveStudent',res.data)
                ElMessage({
                    message: 'O`quvchi holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editStudent(context,payload){
            return await context.dispatch('getAxios',{url:`student/edit/${payload}`})
        },
        async saveStudent(context,payload){
            let res = await context.dispatch('putAxios',{url:`student/save`,data: payload})
            if (res.status == 200){
                context.commit('saveStudent',res.data)
                ElMessage({
                    message: 'O`quvchi yangilandi',
                    type: 'success',
                })
            }
        },
        

        

        async removeStudent(context,payload){
            let res = await context.dispatch('deleteAxios',`student/delete/${payload}`)
            if (res.status == 200){
                context.commit('deleteStudent',payload)
                ElMessage({
                    message: 'O`quvchi o`chirildi',
                    type: 'warning',
                })
            }
        },
        async removeStudentFile(context,payload){
            return await context.dispatch('postAxios',
                {url:`student/files/delete`,
                data: {resFile:payload}
            })
        },
    }
}