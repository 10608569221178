import { ElMessage } from 'element-plus'

export const subject = {
    state: () => ({
        subjects:[],
        subject:{},
        countSubject:0
    }),
    getters:{
        countSubject(state){
            return state.countSubject
        },
        subjects(state){
            return state.subjects.filter(subject => subject.status !==2)
        },
        subject(state){
            return state.subject
        },
        activeSubjects(state){
            return state.subjects.filter(subject => subject.status == 0)
        }
    },
    mutations:{
        countSubject(state,payload){
            state.countSubject = payload
        },
        subjects(state,payload){
            state.subjects = payload
        },
        subject(state,payload){
            state.subject = payload
        },
        newSubject(state,payload){
            state.subjects = [payload,...state.subjects]
            state.countSubject += 1
        },
        saveSubject(state,payload){
            let index = state.subjects.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.subjects[index] = payload
            }
        },
        deleteSubject(state,payload){
            state.subjects = state.subjects.filter(subject => {
                if (subject._id == payload) return false
                return subject
            })
            state.countSubject -= 1
        }
    },
    actions:{
        
        async getSubjects(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'subject',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('subjects',res.data)
            }
        },
        async getSubjectsNoTheme(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'subject/notheme',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('subjects',res.data)
            }
        },
        async getCountSubjects(context,payload){
            let res = await context.dispatch('getAxios',{url:'subject/count',search:payload})
            if (res.status == 200){
                context.commit('countSubject',res.data.subject)
            }
        },
        async changeStatusSubject(context,payload){
            let res = await context.dispatch('getAxios',{url:`subject/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveSubject',res.data)
                ElMessage({
                    message: payload == 2 ? 'Fan o`chirildi' :'Fan holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editSubject(context,payload){
            return await context.dispatch('getAxios',{url:`subject/edit/${payload}`})
        },
        async getSubject(context,payload){
            let res = await context.dispatch('getAxios',{url:`subject/edit/${payload}`})
            if (res.status == 200){
                context.commit('subject',res.data)
            }
        },
        async saveSubject(context,payload){
            let res = await context.dispatch('putAxios',{url:`subject/save`,data: payload})
            if (res.status == 200){
                context.commit('saveSubject',res.data)
                ElMessage({
                    message: 'Fan yangilandi',
                    type: 'success',
                })
            }
        },
        async addSubject(context,payload){
            let res = await context.dispatch('postAxios',{url:'subject',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi fan qo`shildi',
                    type: 'success',
                })
                context.commit('newSubject',res.data)
            }
        },
        
    }
}