import { ElMessage } from 'element-plus';

export const certificate = {
  state: () => ({
    certificates: [],
    certificate: {},
    countCertificate: 0,
    certificateTitle: 'Mening sertifikatlarim',
  }),
  getters: {
    countCertificate(state) {
      return state.countCertificate;
    },
    certificates(state) {
      return state.certificates;
    },
    certificate(state) {
      return state.certificate;
    },
    activeCertificates(state) {
      return state.certificates.filter(
        (certificate) => certificate.status == 0
      );
    },
  },
  mutations: {
    countCertificate(state, payload) {
      state.countCertificate = payload;
    },
    certificates(state, payload) {
      state.certificates = payload;
    },
    certificate(state, payload) {
      state.certificate = payload;
    },
    newCertificate(state, payload) {
      state.certificates = [payload, ...state.certificates];
      state.countCertificate += 1;
    },
    saveCertificate(state, payload) {
      let index = state.certificates.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.certificates[index] = payload;
      }
    },
    deleteCertificate(state, payload) {
      state.certificates = state.certificates.filter((certificate) => {
        if (certificate._id == payload) return false;
        return certificate;
      });
      state.countCertificate -= 1;
    },
  },
  actions: {
    async getCertificates(context, payload) {
      context.commit('loading', true);
      let res = await context.dispatch('getAxios', {
        url: 'certificate',
        search: payload,
      });
      if (res.status == 200) {
        context.commit('loading', false);
        context.commit('certificates', res.data);
      }
    },
    async getMyCertificates({ dispatch, commit }) {
      let res = await dispatch('getAxios', {
        url: 'certificate/my',
      });
      if (res.status) {
        commit('certificates', res.data);
      }
    },
    async getCountCertificates(context, payload) {
      let res = await context.dispatch('getAxios', {
        url: 'certificate/count',
        search: payload,
      });
      if (res.status == 200) {
        context.commit('countCertificate', res.data.count);
      }
    },
    downloadCertificate({ dispatch }, payload) {
      return dispatch('getAxios', {
        url: `certificate/generate/${payload}`,
      });
    },
    async changeStatusCertificate(context, payload) {
      let res = await context.dispatch('getAxios', {
        url: `certificate/status/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          context.commit('deleteCertificate', res.data._id);
          ElMessage({
            message: `${context.state.certificateTitle} o'chirildi`,
            certificate: 'warning',
          });
        } else {
          context.commit('saveCertificate', res.data);
          ElMessage({
            message: `${context.state.certificateTitle} holati o'zgartirildi`,
            certificate: 'success',
          });
        }
      }
    },
    async editCertificate(context, payload) {
      return await context.dispatch('getAxios', {
        url: `certificate/${payload}`,
      });
    },
    async saveCertificate(context, payload) {
      let res = await context.dispatch('putAxios', {
        url: `certificate`,
        data: payload,
      });

      if (res.status == 200) {
        context.commit('saveCertificate', res.data);
        ElMessage({
          message: `${context.state.certificateTitle} yangilandi`,
          certificate: 'success',
        });
      }
    },
    async addCertificate(context, payload) {
      let res = await context.dispatch('postAxios', {
        url: 'certificate',
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: `Yangi ${context.state.certificateTitle.toLowerCase()} qo'shildi`,
          certificate: 'success',
        });
        context.commit('newCertificate', res.data);
      }
    },
  },
};
