<template>
  <component :is="layout" />
</template>

<script>
import AdminLayout from "@/layout/adminLayout.vue";
import StudentLayout from "@/layout/studentLayout.vue";
import OlimpianLayout from "@/layout/olimpianLayout.vue";
import TestersLayout from "@/layout/testersLayout.vue";
import DirectorLayout from "@/layout/directorLayout.vue";
import AuthLayout from "@/layout/authLayout.vue";
import teacherLayout from "@/layout/teacherLayout.vue";
export default {
  components: {
    "teacher-layout": teacherLayout,
    "admin-layout": AdminLayout,
    "director-layout": DirectorLayout,
    "student-layout": StudentLayout,
    "olympian-layout": OlimpianLayout,
    "auth-layout": AuthLayout,
    "testers-layout": TestersLayout,
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
  methods: {
    checkUser() {
      if (this.$cookies.isKey("quite-user")) {
        this.$store.dispatch("checkUser", this.$cookies.get("quite-user"));
      } else {
        this.$router.push("/login");
      }
    },
  },
  watch: {
    $route(to) {
      if (to.name == "login") {
        this.$store.dispatch("setLayout", "auth-layout");
      } else {
        this.checkUser();
      }
    },
  },
  mounted() {
    this.checkUser();
    document.title = "UMFT";
  },
};
</script>

<style lang="scss">
@import "./styles/reset.scss";
@import "./styles/grid.scss";
@import "./styles/main.scss";
@import "./styles/lib/class.scss";
</style>
