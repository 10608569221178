<template>

    <component :is="user.role"/>
  
</template>

<script>
import { mapGetters } from 'vuex';
import HomePage from './olympian/HomePage.vue';
// import Chart from 'chart.js/auto'
import HomeStudent from './student/HomeStudent.vue';
import HomeAdminPage from './admin/HomePage.vue';
export default {
  name: 'HomeView',
  components: {
    'student': HomeStudent,
    'olympian': HomePage,
    'admin': HomeAdminPage,
    'testers': HomePage
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  
}
</script>

<style lang="scss">
@import '@/styles/view/home.scss';
</style>
