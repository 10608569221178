import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import {adminLinks, adminSettings} from './admin'
import { bossLinks, bossSettings } from './boss'

import store from '@/store'
import { studentLinks } from './student'
import { olimpianLinks } from './olimpian'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginPage.vue'),
  },
  // {
  //   path: '/testt',
  //   name: 'testt',
  //   component: () =>
  //     import(/* webpackChunkName: "testt" */ '../views/TestPage.vue'),
  // },
  {
    path: '/quiz/:id',
    name: 'quiz',
    component: () => import(/* webpackChunkName: "quiz" */ '../views/olympian/QuizPage.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/ProfilePage.vue'),
  },

  {
    path: '/history/:id',
    name: 'history',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/history/HistoryList.vue'),
    meta: {
      role: ['admin'],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () =>
      import(/* webpackChunkName: "setting" */ '../views/SettingsList.vue'),
    children: [
      ...adminSettings,
      ...bossSettings,
      {
        path: '',
        name: 'settingall',
        component: () =>
          import(
            /* webpackChunkName: "setting" */ '../views/settings/AllSettings.vue'
          ),
      },
    ],
  },
  ...studentLinks,
  ...bossLinks,
  ...olimpianLinks,
  ...adminLinks,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from) => {
  if (store.getters.role && to.meta.role){
    if (!to.meta.role.includes(store.getters.role)){
      return { name: from ? from.name : 'home' }
    }
  }
  
})


export default router
