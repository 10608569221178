import { ElMessage } from 'element-plus'

export const type = {
    state: () => ({
        types:[],
        type:{},
        countType:0,
        typeTitle:'Ta`lim shakli'
    }),
    getters:{
        countType(state){
            return state.countType
        },
        types(state){
            return state.types
        },
        type(state){
            return state.type
        },
        activeTypes(state){
            return state.types.filter(type => type.status == 0)
        }
    },
    mutations:{
        countType(state,payload){
            state.countType = payload
        },
        types(state,payload){
            state.types = payload
        },
        type(state,payload){
            state.type = payload
        },
        newType(state,payload){
            state.types = [payload,...state.types]
            state.countType += 1
        },
        saveType(state,payload){
            let index = state.types.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.types[index] = payload
            }
        },
        deleteType(state,payload){
            state.types = state.types.filter(type => {
                if (type._id == payload) return false
                return type
            })
            state.countType -= 1
        }
    },
    actions:{
        async getTypes(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'type',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('types',res.data)
            }
        },
        async getCountTypes(context,payload){
            let res = await context.dispatch('getAxios',{url:'type/count',search:payload})
            if (res.status == 200){
                context.commit('countType',res.data.count)
            }
        },
        async changeStatusType(context,payload){
            let res = await context.dispatch('getAxios',{url:`type/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                if (payload.status == 2){
                    context.commit('deleteType',res.data._id)
                    ElMessage({
                    message: `${context.state.typeTitle} o'chirildi`,
                        type: 'warning',
                    })
                } else {
                    context.commit('saveType',res.data)
                    ElMessage({
                    message: `${context.state.typeTitle} holati o'zgartirildi`,
                        type: 'success',
                    })

                }
            }
        },
        async editType(context,payload){
            return await context.dispatch('getAxios',{url:`type/${payload}`})
        },
        async saveType(context,payload){
            let res = await context.dispatch('putAxios',{url:`type`,data: payload})
            
            if (res.status == 200){
                context.commit('saveType',res.data)
                ElMessage({
                    message: `${context.state.typeTitle} yangilandi`,
                    type: 'success',
                })
            }
        },
        async addType(context,payload){
            let res = await context.dispatch('postAxios',{url:'type',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: `Yangi ${context.state.typeTitle.toLowerCase()} qo'shildi`,
                    type: 'success',
                })
                context.commit('newType',res.data)
            }
        },
    }
}