import { ElMessage } from "element-plus";
import router from "@/router";
export const result = {
  state: () => ({
    results: [],
    countResult: 0,
    result: {},
    resultProgress: 0,
  }),
  getters: {
    resultProgress({ resultProgress }) {
      return resultProgress;
    },
    countResult(state) {
      return state.countResult;
    },
    results(state) {
      return state.results;
    },
    activeResults(state) {
      return state.results.filter((result) => result.status == 0);
    },
    activeSortResults(state) {
      let results = state.results.filter((result) => result.status == 0);
      return results.sort();
    },
    result({ result }) {
      return result;
    },
  },
  mutations: {
    countResult(state, payload) {
      state.countResult = payload;
    },

    result(state, payload) {
      let p = 0;
      if (payload.tests) {
        payload.tests = payload.tests.map((test) => {
          let startCodeIndex = test.test.question.indexOf("<code>");
          let endCodeIndex = test.test.question.indexOf("</code>");
          test.test.formula = "";
          test.test.html = test.test.question.slice();
          if (startCodeIndex >= 0) {
            test.test.formula = test.test.question.slice(
              startCodeIndex + 6,
              endCodeIndex
            );
            test.test.html =
              test.test.question.slice(0, startCodeIndex) +
              test.test.question.slice(endCodeIndex);
          }

          p += test.answers.length > 0 ? 1 : 0;
          test.answer = test.answers[0] ? test.answers[0] : "";
          test.variants = test.variants.map((variant) => {
            let startCodeIndex = variant.indexOf("<code>");
            let endCodeIndex = variant.indexOf("</code>");
            let formula = "";
            let html = variant.slice();
            let original = variant.slice();
            if (startCodeIndex >= 0) {
              formula = variant.slice(startCodeIndex + 6, endCodeIndex);
              html =
                variant.slice(0, startCodeIndex) + variant.slice(endCodeIndex);
            }
            return { formula, html, original };
          });
          test.test.formula = test.test.formula.split(" ").join("\\:");
          return test;
        });
        payload.progress = Math.round((p / payload.tests.length) * 100);
        let time = new Date(payload.testprocess.time);
        let minutes = time.getMinutes() > 0 ? time.getMinutes() * 60 : 1;
        let hours = time.getHours() > 0 ? time.getHours() * 3600 * 1000 : 1;
        payload.deadline =
          new Date(payload.createdAt).valueOf() + 1000 * minutes + hours;
      }
      state.result = payload;
    },
    upResult(state, payload) {
      let p = 0;
      state.result.tests = state.result.tests.map((test) => {
        if (test._id == payload.test_id) {
          test.answers = payload.answers;
        }
        p += test.answers.length > 0 ? 1 : 0;
        return test;
      });
      state.result.progress = Math.round((p / state.result.tests.length) * 100);
    },
    results(state, payload) {
      state.results = payload;
    },
    newResult(state, payload) {
      state.results = [payload, ...state.results];
      state.countResult += 1;
    },
    saveResult(state, payload) {
      let index = state.results.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.results[index] = payload;
      }
    },
    deleteResult(state, payload) {
      state.results = state.results.filter((result) => {
        if (result._id == payload) return false;
        return result;
      });
      state.countResult -= 1;
    },
  },
  actions: {
    async getResults(context, payload) {
      context.commit("loading", true);
      let res = await context.dispatch("getAxios", {
        url: "result",
        search: payload,
      });
      if (res.status == 200) {
        context.commit("loading", false);
        context.commit("results", res.data);
      }
    },

    async addResult(context, payload) {
      let res = await context.dispatch("postAxios", {
        url: "result",
        data: payload,
      });
      if (res.status == 201) {
        router.push("/result");
        ElMessage({
          message: "Yangi resultlar qo`shildi",
          type: "success",
        });
        context.commit("newResult", res.data);
      }
    },

    async editResult(context, payload) {
      return await context.dispatch("getAxios", {
        url: `result/edit/${payload}`,
      });
    },
    async saveResult(context, payload) {
      let res = await context.dispatch("putAxios", {
        url: `result`,
        data: payload,
      });
      if (res.status == 200) {
        context.commit("saveResult", res.data);
        router.push("/result");
        ElMessage({
          message: "Result yangilandi",
          type: "success",
        });
      }
    },
    async startResult({ dispatch, commit }, payload) {
      if (payload) {
        let res = await dispatch("getAxios", {
          url: `result/`,
          search: payload,
        });
        if (res.status == 200) {
          // console.log(res.data)
          if (res.data == "no") {
            router.push("/");
          } else {
            commit("result", res.data);
          }
        }
      }
    },
    async startPushResult({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", { url: `result/`, search: payload });
      if (res.status == 200) {
        commit("result", res.data);
        router.push(`/quiz/${payload.procid}`);
      }
    },
    async upAnswer({ dispatch, commit }, payload) {
      if (payload) {
        let res = await dispatch("postAxios", {
          url: "result/check",
          data: payload,
        });
        if (res.status == 200) {
          commit("upResult", res.data);
        }
      }
    },
    async finalResult({ dispatch }, payload) {
      // console.log(payload)
      return await dispatch("postAxios", { url: "result", data: payload });
    },
    async closeResult({ dispatch }, payload) {
      await dispatch("getAxios", {
        url: `result/checkall/${payload}`,
      });

    },
  },
};
