import { ElMessage } from "element-plus";

export const direction = {
  state: () => ({
    directions: [],
    direction: {},
    countDirection: 0,
    directionTitle: "Ta`lim yo`nalish",
  }),
  getters: {
    countDirection(state) {
      return state.countDirection;
    },
    directions(state) {
      return state.directions.filter((d) => d.lvl == 0 || !d.lvl);
    },
    direction(state) {
      return state.direction;
    },
    activeDirections(state) {
      return state.directions.filter((direction) => direction.status == 0);
    },
  },
  mutations: {
    countDirection(state, payload) {
      state.countDirection = payload;
    },
    directions(state, payload) {
      state.directions = payload;
    },
    direction(state, payload) {
      state.direction = payload;
    },
    newDirection(state, payload) {
      state.directions = [payload, ...state.directions];
      state.countDirection += 1;
    },
    saveDirection(state, payload) {
      let index = state.directions.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.directions[index] = payload;
      }
    },
    deleteDirection(state, payload) {
      state.directions = state.directions.filter((direction) => {
        if (direction._id == payload) return false;
        return direction;
      });
      state.countDirection -= 1;
    },
  },
  actions: {
    async getDirections(context, payload) {
      context.commit("loading", true);
      let res = await context.dispatch("getAxios", {
        url: "direction",
        search: payload,
      });
      if (res.status == 200) {
        context.commit("loading", false);
        context.commit("directions", res.data);
      }
    },
    async getCountDirections(context, payload) {
      let res = await context.dispatch("getAxios", {
        url: "direction/count",
        search: payload,
      });
      if (res.status == 200) {
        context.commit("countDirection", res.data.count);
      }
    },
    async changeStatusDirection(context, payload) {
      let res = await context.dispatch("getAxios", {
        url: `direction/status/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          context.commit("deleteDirection", res.data._id);
          ElMessage({
            message: `${context.getters.directionTitle} o'chirildi`,
            type: "warning",
          });
        } else {
          context.commit("saveDirection", res.data);
          ElMessage({
            message: `${context.getters.directionTitle} holati o'zgartirildi`,
            type: "success",
          });
        }
      }
    },
    async editDirection(context, payload) {
      return await context.dispatch("getAxios", {
        url: `direction/${payload}`,
      });
    },
    async saveDirection(context, payload) {
      let res = await context.dispatch("putAxios", {
        url: `direction`,
        data: payload,
      });

      if (res.status == 200) {
        context.commit("saveDirection", res.data.direction);
        ElMessage({
          message: `${context.getters.directionTitle} yangilandi`,
          type: "success",
        });
      }
    },
    async addDirection(context, payload) {
      let res = await context.dispatch("postAxios", {
        url: "direction",
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: `Yangi ${context.state.directionTitle.toLowerCase()} qo'shildi`,
          type: "success",
        });
        context.commit("newDirection", res.data);
      }
    },
  },
};
