export const studentMenu = [
    {
        path:'/requests',
        icon:'document',
        name: 'request',
        title:'Arizalarim',
        component:() => import(/* webpackChunkName: "request" */ '../views/student/RequestList.vue'),
        meta: { role:['student'] },
        children:[
            {
                path:'',
                name: 'allrequest',
                component:() => import(/* webpackChunkName: "request" */ '../views/student/request/AllRequest.vue'),
                meta: { role:['student'] },
            },
            {
                path:'add',
                name: 'addrequest',
                component:() => import(/* webpackChunkName: "addrequest" */ '../views/student/request/AddRequest.vue'),
                meta: { role:['student'] },
            }
        ]
    },
    
]

export const studentLinks = [
    ...studentMenu,
    {
        path:'/test/edit/:id',
        name: 'editTest',
        component:() => import(/* webpackChunkName: "editTest" */ '../views/boss/test/EditTest.vue'),
    },
    {
        path:'/testpack/edit/:id',
        name: 'editTestpack',
        component:() => import(/* webpackChunkName: "editTestpack" */ '../views/boss/testpack/EditTestpack.vue'),
    },
]