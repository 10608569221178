import { ElMessage } from "element-plus";

export const user = {
  state: () => ({
    users: [],
    countUser: 0,
    roles: [],
  }),
  getters: {
    roles(state) {
      return state.roles;
    },
    countUser(state) {
      return state.countUser;
    },
    users(state) {
      return state.users;
    },
    activeUsers(state) {
      return state.users.filter((user) => user.status == 0);
    },
    activeSortUsers(state) {
      let users = state.users.filter((user) => user.status == 0);
      return users.sort();
    },
  },
  mutations: {
    countUser(state, payload) {
      state.countUser = payload;
    },
    users(state, payload) {
      state.users = payload;
    },
    newUser(state, payload) {
      state.users = [payload, ...state.users];
      state.countUser += 1;
    },
    saveUser(state, payload) {
      let index = state.users.findIndex((dep) => dep._id == payload._id);
      if (index !== -1) {
        state.users[index] = payload;
      }
    },
    roles(state, payload) {
      state.roles = payload;
    },
    deleteUser(state, payload) {
      state.users = state.users.filter((user) => {
        if (user._id == payload) return false;
        return user;
      });
      state.countUser -= 1;
    },
  },
  actions: {
    async getRoles(context) {
      let res = await context.dispatch("getAxios", { url: "user/roles" });
      if (res.status == 200) {
        context.commit("roles", res.data);
      }
    },

    async getUsers(context, payload) {
      context.commit("loading", true);
      let res = await context.dispatch("getAxios", {
        url: "user",
        search: payload,
      });
      // console.log(res.data);
      context.commit("loading", false);
      context.commit("users", res.data);
    },

    async getUserCount({ commit, dispatch }, payload) {
      commit("loading", true);
      let res = await dispatch("getAxios", {
        url: "user/count",
        search: payload,
      });
      commit("loading", false);
      commit("countUser", res.data.user);
    },

    async addUser(context, payload) {
      let res = await context.dispatch("postAxios", {
        url: "user",
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: "Yangi foydalanuvchi qo`shildi",
          type: "success",
        });
        context.commit("newUser", res.data);
      }
    },

    async changeStatusUser(context, payload) {
      let res = await context.dispatch("getAxios", {
        url: `user/status/${payload._id}/${payload.status}`,
      });

      if (res.status == 200) {
        context.commit("saveUser", res.data);
        ElMessage({
          message: "Foydalanuvchi holati o`zgartirildi",
          type: "success",
        });
      }
    },
    async editUser(context, payload) {
      return await context.dispatch("getAxios", {
        url: `user/edit/${payload}`,
      });
    },
    async saveUser(context, payload) {
      let res = await context.dispatch("putAxios", {
        url: `user`,
        data: payload,
      });
      if (res.status == 200) {
        context.commit("saveUser", res.data);
        ElMessage({
          message: "Foydalanuvchi yangilandi",
          type: "success",
        });
      }
    },
    async removeUser(context, payload) {
      let res = await context.dispatch("deleteAxios", `user/${payload}`);
      if (res.status == 200) {
        context.commit("deleteUser", payload);
        ElMessage({
          message: "Foydalanuvchi o`chirildi",
          type: "warning",
        });
      }
    },
    async getOlimpiaders({ dispatch }, payload) {
      return await dispatch("getAxios", {
        url: `user/olimp/${payload}`,
      });
    },
    async removeUserFile(context, payload) {
      return await context.dispatch("postAxios", {
        url: `user/files/delete`,
        data: { resFile: payload },
      });
    },
    excelUsers({ dispatch }, payload) {
      return dispatch("getAxios", {
        url: "user/excel",
        search: payload,
      });
    },
  },
};
