export const layoutApp = {
    state: () => ({
        layout: 'auth-layout',
    }),
    getters: {
        layout(state){
            return state.layout
        },
    },
    mutations:{
        layout(state,payload){
            state.layout = payload
        }
    },
    actions:{
        setLayout(context,payload){
            context.commit('layout',payload)
        }
    }
}